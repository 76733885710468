/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Menu, MenuItem, Grid, Typography, Avatar } from '@mui/material';
import threeDot from '../../assets/static_images/threedot.svg';
import Reacts from 'components/Reacts';
import Blank from '../../assets/static_images/blank.svg';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import ReplayComment from './ReplayComment';
import EditComment from './EditComment';
import DeleteComment from './DeleteComment';
import ViewReactModal from 'components/modals/ViewReactModal';
import ReactSummary from './ReactSummary';
import axios from 'lib/axios';
import { socket } from '../../socket';
import { calculateTimeDifference } from 'Helpers';

const LevelThreeCommentBox = ({ comment, setParentComments, setChildComment, type, postOwnerId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [replayComment, setReplayComment] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [body, setBody] = useState(comment.comment_body);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openViewReactModal, setOpenViewReactModal] = useState(false);
    const [reactSummary, setReactSummary] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const currentUser = JSON.parse(window.localStorage.getItem('authUser'));
    const isCommentOwner = currentUser?.user_id === comment?.user?.user_id;
    const isPostOwner = currentUser?.user_id === postOwnerId;
    const canModifyComment = isCommentOwner || isPostOwner;

    const handleClick = (event) => {
        if (canModifyComment) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const reacts = [
        {
            react_id: 1,
            react_name: 'Like'
        },
        {
            react_id: 2,
            react_name: 'Love'
        },
        {
            react_id: 3,
            react_name: 'HaHa'
        },
        {
            react_id: 4,
            react_name: 'Wow'
        },
        {
            react_id: 5,
            react_name: 'Sad'
        },
        {
            react_id: 6,
            react_name: 'Angry'
        }
    ];

    const fetchReactSummary = async () => {
        try {
            setIsLoading(true);
            const requestBody = {
                comment_id: comment.comment_id
            };
            if (type && type !== '') {
                const key = `${type}_id`;
                requestBody[key] = comment[key];
            }
            const res = await axios.post(`/${type}-comment-reacts/mini-statement`, requestBody);
            if (res.data.success) {
                setIsLoading(false);
                setReactSummary((prev) => {
                    prev = res.data.summary;
                    return prev;
                });
                if (res.data.my_react && res.data.my_react.react) {
                    setReaction((prev) => {
                        prev = res.data.my_react.react.react_name;
                        return prev;
                    });
                    if (res.data.my_react.react.react_id == 1) {
                        setReactionImg(Like);
                    } else if (res.data.my_react.react.react_id == 2) {
                        setReactionImg(Love);
                    } else if (res.data.my_react.react.react_id == 3) {
                        setReactionImg(Haha);
                    } else if (res.data.my_react.react.react_id == 4) {
                        setReactionImg(Wow);
                    } else if (res.data.my_react.react.react_id == 5) {
                        setReactionImg(Sad);
                    } else if (res.data.my_react.react.react_id == 6) {
                        setReactionImg(Angry);
                    }
                    setReacted(true);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchReactSummary();
    }, [comment]);

    useEffect(() => {
        listen_to_channel();
    }, []);

    function listen_to_channel() {
        socket.on(`${type === 'post' ? 'comment_react_added' : `${type}_comment_react_added`}`, (comment_react) => {
            if (comment_react?.comment_id == comment?.comment_id) {
                fetchReactSummary();
            }
        });
    }

    return (
        <>
            <Box sx={{ display: 'flex', gap: '10px', mt: '15px' }}>
                <Box>
                    <Avatar
                        style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                        src={comment.user?.profile_image}
                        alt={comment.user?.first_name}
                    />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ background: '#F2F2F2', px: 2, py: 1, width: '100%', borderRadius: '0px 10px 10px 10px' }}>
                        <Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">
                                    {comment.user.first_name} {comment.user.last_name}
                                </Typography>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography sx={{ fontSize: '12px' }}>{calculateTimeDifference(comment.createdAt)}</Typography>

                                    {canModifyComment && (
                                        <Box
                                            onClick={handleClick}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1rem',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                style={{ width: '20px', height: '20px', objectFit: 'cover' }}
                                                src={threeDot}
                                                alt="profile"
                                            />
                                        </Box>
                                    )}
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {isCommentOwner && (
                                            <MenuItem
                                                onClick={() => {
                                                    setEditorOpen(true);
                                                    handleClose();
                                                }}
                                            >
                                                Edit
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                handleClose();
                                            }}
                                        >
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                            {/* comment */}
                            <Box>
                                {editorOpen ? (
                                    <EditComment
                                        setParentComments={setParentComments}
                                        setBody={setBody}
                                        model={type}
                                        setEditorOpen={setEditorOpen}
                                        body={comment.comment_body}
                                        commentId={comment.comment_id}
                                    />
                                ) : (
                                    <Typography variant="p">{comment.comment_body}</Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ position: 'relative' }} mt={1}>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    variant="p"
                                    onClick={() => {
                                        setReactOpen(!reactOpen);
                                    }}
                                    sx={{
                                        pr: '10px',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        userSelect: 'none'
                                    }}
                                >
                                    {reacted ? (
                                        <Box display="flex" alignItems="center" gap="0.5rem">
                                            <Box>
                                                <img
                                                    src={reactionImg}
                                                    alt="icn"
                                                    style={{
                                                        height: '1rem',
                                                        width: '1rem'
                                                    }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ opacity: '0.8', fontSize: '0.85rem' }}>{reaction}</Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box display="flex" alignItems="center" gap="0.5rem">
                                            <Box>
                                                <img
                                                    src={Blank}
                                                    alt="icn"
                                                    style={{
                                                        height: '1rem',
                                                        width: '1rem'
                                                    }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '0.85rem' }}>Like</Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                {reactSummary.length > 0 && (
                                    <>
                                        {isLoading ? (
                                            <Box
                                                sx={{
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '50%',
                                                    background: 'lightgray',
                                                    mr: '3px'
                                                }}
                                            ></Box>
                                        ) : (
                                            <Box
                                                onClick={() => {
                                                    setOpenViewReactModal(true);
                                                }}
                                            >
                                                <ReactSummary reactSummary={reactSummary} />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                        {reactOpen && (
                            <Grid
                                item
                                sx={{
                                    zIndex: '1024',
                                    position: 'absolute',
                                    top: '-4rem',
                                    left: '1.5rem',
                                    width: '23rem'
                                }}
                            >
                                <Reacts
                                    reacts={reacts}
                                    setReactOpen={setReactOpen}
                                    setReacted={setReacted}
                                    setReactionImg={setReactionImg}
                                    setReaction={setReaction}
                                    model={`${type}-comment`}
                                    modelValue={comment}
                                />
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Box>
            {openDeleteModal && (
                <DeleteComment
                    setStates={setParentComments}
                    commentId={comment.comment_id}
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    type={type}
                />
            )}
            {openViewReactModal && (
                <ViewReactModal
                    post={comment}
                    setOpenModal={setOpenViewReactModal}
                    endpoint={`/${type}-comment-reacts/list`}
                    type={type}
                    openModal={openViewReactModal}
                />
            )}
        </>
    );
};

export default LevelThreeCommentBox;
