import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

export default function TaskProjectGoalLayout() {
    const activeStyle = {
        padding: '.5em',
        marginRight: '.50rem',
        color: '#1E7C88',
        textDecoration: 'none',
        borderBottom: '2px solid #1E7C88'
    };

    const inactiveStyle = {
        padding: '.5em',
        marginRight: '.50rem',
        color: 'rgba(0,0,0,.6)',
        textDecoration: 'none'
    };

    return (
        <Box sx={{ maxHeight: { xs: '100%', md: 'calc(100vh - 7.5rem)' }, overflowY: 'auto' }}>
            {/* tab */}
            <Box display="flex" id="tab" alignItems="center" justifyContent="center" mb={2}>
                <NavLink to="task" style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)} className="cursor-pointer">
                    <Typography variant="h5">Task</Typography>
                </NavLink>

                <NavLink to="project" style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)} className="cursor-pointer">
                    <Typography variant="h5">Projects</Typography>
                </NavLink>

                <NavLink to="goal" style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)} className="cursor-pointer">
                    <Typography variant="h5">Goals</Typography>
                </NavLink>
            </Box>
            <Outlet />
        </Box>
    );
}
