import { Box, Card, Button, OutlinedInput, Badge, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'lib/axios';
import { toast } from 'sonner';

const AppCategoryModal = ({ setOpenModal, setCategories, selectedApp }) => {
    const [categoryName, setCategoryName] = useState(selectedApp ? selectedApp?.title : '');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', categoryName);

        try {
            setIsLoading(true);
            const res = selectedApp
                ? await axios.patch(`/app-store/category/${selectedApp.application_category_id}`, formData)
                : await axios.post(`/app-store/category`, formData);

            if (res.data.success) {
                if (selectedApp) {
                    setCategories((prev) =>
                        prev.map((app) =>
                            app.application_category_id === selectedApp.application_category_id ? { ...app, title: categoryName } : app
                        )
                    );
                } else {
                    setCategories((prev) => [res.data.category, ...prev]);
                }

                toast.success(res.data.message);

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
            setOpenModal(false);
        }
    };

    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh', width: '100vw' }}>
                <Card sx={{ padding: '1rem', position: 'relative', width: { md: '30vw', xs: '100%' } }}>
                    <Badge
                        color="primary"
                        badgeContent={'X'}
                        className="cursor-pointer"
                        onClick={() => setOpenModal(false)}
                        sx={{ marginLeft: 'auto', marginRight: '1rem', position: 'absolute', top: '1rem', right: '0' }}
                    ></Badge>
                    <Box>
                        <Box mt={2}>
                            {/* Name */}
                            <Box>
                                <Typography variant="h6"> Category Name</Typography>
                                <OutlinedInput
                                    type="text"
                                    value={categoryName}
                                    color="primary"
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    placeholder="Create a category"
                                    fullWidth
                                />
                            </Box>

                            {/* Submit */}
                            <Box mt={2}>
                                <Button
                                    disabled={isLoading}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    fullWidth
                                    sx={{ backgroundColor: 'primary.main', color: 'white' }}
                                >
                                    {isLoading ? 'Loading....' : 'Submit'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default AppCategoryModal;
