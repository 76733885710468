import React, { useState } from 'react';
import { Box, Typography, Button, InputLabel, Card, OutlinedInput, Modal, Badge } from '@mui/material';
import axios from 'lib/axios';
import { toast } from 'sonner';

export default function CreateFeedback({ open, handleClose }) {
    const [formData, setFormData] = useState({
        subject: '',
        description: '',
        phone: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const user = JSON.parse(localStorage.getItem('authUser'));

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user?.user_id) {
            toast.error('User not authenticated. Please log in.');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post('/feedback', {
                subject: formData.subject,
                description: formData.description,
                phone_number: formData.phone,
                user_id: user.user_id
            });

            if (response.data.success) {
                toast.success('Feedback submitted successfully!');
                setFormData({
                    subject: '',
                    description: '',
                    phone: ''
                });
                handleClose();
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast.error(error.response?.data?.message || 'Error submitting feedback. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="feedback-modal" aria-describedby="feedback-form-modal">
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '600px',
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Box
                        className="cursor-pointer"
                        onClick={handleClose}
                        sx={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: '#e4eff1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography variant="h4" color="primary">
                            ×
                        </Typography>
                    </Box>
                </Box>

                <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
                    Give a Feedback
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Subject</InputLabel>
                        <OutlinedInput
                            fullWidth
                            placeholder="Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Description</InputLabel>
                        <textarea
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows={4}
                            style={{ width: '100%', fontSize: '1rem', borderRadius: '4px' }}
                        />
                    </Box>

                    <Box sx={{ mb: 3 }}>
                        <InputLabel>Phone Number</InputLabel>
                        <OutlinedInput
                            fullWidth
                            placeholder="Phone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            type="tel"
                        />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                minWidth: '150px',
                                p: 1,
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(30, 124, 136, 0.5)'
                                }
                            }}
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit Feedback'}
                        </Button>
                    </Box>
                </form>
            </Card>
        </Modal>
    );
}
