import { Box, Grid, Card, Typography } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { convertDateAndTime, processToUpper, timeDiffer } from 'Helpers';
import ThreeDot from '../../assets/static_images/threedot.svg';
const NewsHeader = ({ news, openOption, setOpenOption, setOpenEditor, setOpenDeleteModal, canEdit, canDelete }) => {
    return (
        <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
            <UserAvatarComponent userData={news.user} />
            <Box sx={{ marginLeft: '1rem' }} flex="1">
                <Typography variant="h6">{`${news?.user?.first_name} ${news?.user?.last_name}`}</Typography>
                <Box display="flex" gap="0.5rem">
                    <Typography sx={{ fontSize: '14px', opacity: '0.5' }}>{`${processToUpper(
                        news.user?.role?.role_type ?? ''
                    )}`}</Typography>
                    <Typography variant="subtitle1" sx={{ opacity: '0.5', fontSize: '14px' }}>
                        {convertDateAndTime(news.createdAt)}
                    </Typography>
                </Box>
            </Box>
            {JSON.parse(window.localStorage.getItem('authUser'))?.user_id == news.user_id && canEdit && (
                <Grid
                    item
                    sm={1}
                    className="cursor-pointer"
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    onClick={() => {
                        setOpenOption(!openOption);
                        setTimeout(() => {
                            // textInput?.current.focus();
                            // textInput?.current.setSelectionRange(content.length, content.length);
                        }, 0.5);
                    }}
                >
                    <img src={ThreeDot} alt="dot" style={{ marginLeft: 'auto' }} />
                </Grid>
            )}
            {openOption && canEdit ? (
                <Grid
                    item
                    sx={{
                        zIndex: '1024',
                        position: 'absolute',
                        top: '2.5rem',
                        right: '0rem',
                        width: '6rem',
                        height: '10rem'
                    }}
                >
                    <Card sx={{ borderRadius: '10px', width: '100%' }}>
                        <Grid container display="flex" direction="column" aligItems="center" justifyContent="center">
                            {canEdit && (
                                <Grid
                                    item
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ borderBottom: '1px solid #000000' }}
                                    onClick={() => {
                                        setOpenEditor(true);
                                    }}
                                    className="cursor-pointer"
                                >
                                    <Typography>Edit</Typography>
                                </Grid>
                            )}
                            {canDelete && (
                                <Grid
                                    item
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenDeleteModal(true);
                                    }}
                                >
                                    <Typography>Delete</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default NewsHeader;
