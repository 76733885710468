/* eslint-disable prettier/prettier */
// project import
import pages from '../../../../../menu-items/pages';
import dashboard from '../../../../../menu-items/dashboard';
import utilities from '../../../../../menu-items/utilities';
import support from '../../../../../menu-items/support';
import companies from '../../../../../menu-items/comapanies';
import notice from '../../../../../menu-items/notice';
import Users from '../../../../../menu-items/employees';
import Districts from '../../../../../menu-items/district';
import Divisions from '../../../../../menu-items/division';
import Locations from '../../../../../menu-items/location';
import Movements from '../../../../../menu-items/movement';
import Roles from '../../../../../menu-items/role';
import Permissions from '../../../../../menu-items/permission';
import project from '../../../../../menu-items/project';
import analytics from '../../../../../menu-items/analytics';
import subscription from '../../../../../menu-items/subscription';
import client_subscription from '../../../../../menu-items/clientSubscription';
import OrganizationStructure from '../../../../../menu-items/functions';
import OfficeLocation from '../../../../../menu-items/officeLocation';
import PermissionLevel from '../../../../../menu-items/permitionLevel';
import MenuStatus from '../../../../../menu-items/menuStatus';
import appStore from '../../../../../menu-items/appStore';
import footNote from 'menu-items/footNote';
import axios from 'lib/axios';
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useState, useEffect } from 'react';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
import HasPermission from 'utils/HasPermission';

const Navigation = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.post(`/users/user`, {
                    id: JSON.parse(localStorage.getItem('authUser'))?.user_id
                });
                if (response.data.data.role_id === 1) {
                    setIsSuperAdmin(true);
                } else if (response.data.data.is_admin) {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchUserData();
        // HasPermission(setIsSuperAdmin, 'Company', 'create', 'create_company');
        if (isSuperAdmin) {
            setItems([dashboard, companies, subscription, analytics, Permissions]);
        } else if (isAdmin) {
            setItems([dashboard, Users, OrganizationStructure, project, MenuStatus, analytics, Movements, client_subscription]);
        }

        // if (isAdmin) {
        //     setItems([
        //         dashboard,
        //         Users,
        //         OrganizationStructure,
        //         project,
        //         // OfficeLocation,
        //         // PermissionLevel,
        //         MenuStatus,
        //         analytics,
        //         // notice,
        //         Movements,
        //         client_subscription
        //         // footNote,
        //         // appStore
        //     ]);
        // } else {
        //     HasPermission(setIsSupervisor, 'Project', 'supervise', 'supervise_project');
        // }

        if (isSupervisor && !isAdmin) {
            setItems([dashboard]);
        }
    }, [isAdmin, isSuperAdmin, isSupervisor]);

    const navGroups = items?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
