import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TabSelector from 'components/TabSelector';
import BasicInfo from './settings/BasicInfo';
import ContactInfo from './settings/ContactInfo';
import ChangeNotifications from './settings/ChangeNotifications';
import ChangeHomepage from './settings/ChangeHomepage';
import ChangePassword from './settings/ChangePassword';
import { Chip, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Alert } from '@mui/lab';
import { Link, InputLabel, OutlinedInput, Snackbar, Box } from '@mui/material';
const Settings = () => {
    const theme = useTheme();
    const [userData, setUserDate] = useState();
    const [activeTab, setActiveTab] = useState('changepass');
    const [openSnack, setShowSnack] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    function handleSnackClose() {
        setShowSnack(false);
    }
    const [tabs, setTabs] = useState({
        // basicinfo: (
        //     <BasicInfo
        //         showSnack={(isError, message) => {
        //             setSnackOptions({ isError, message });
        //             setShowSnack(true);
        //         }}
        //     />
        // ),
        contantinfo: (
            <ContactInfo
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        changepass: (
            <ChangePassword
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        change_notification: (
            <ChangeNotifications
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        change_homepage: (
            <ChangeHomepage
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        )
    });
    useEffect(() => {
        axios
            .post(`/users/user`, {
                id: JSON.parse(window.localStorage.getItem('authUser'))?.user_id.toString()
            })
            .then(({ data }) => {
                setUserDate(data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <Grid sm={12} container>
            <Grid md={3} sm={12} item sx={{ height: { md: '100%' }, overFlowY: 'scroll' }}>
                {userData ? (
                    <Grid container sm={12} display="flex" direction="column" spacing={1} sx={{ paddingTop: '2rem' }}>
                        <Grid item sm={12}>
                            <Grid container sm={12} display="flex" direction="column">
                                <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                    <Typography sx={{ fontWeight: '700' }} variant="h5" color="primary">
                                        My Settings
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    paddingTop: '1rem',
                                    paddingX: '0px',
                                    display: 'flex',
                                    flexDirection: { md: 'column', xs: 'row' },
                                    width: { md: '100%', xs: '90vw' },
                                    overflowX: 'scroll'
                                }}
                            >
                                {/* <Box sx={{ paddingY: '0px' }}>
                                    <ListItemButton
                                        selected={activeTab == 'basicinfo' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('basicinfo');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'basicinfo' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    Edit Profile
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box> */}
                                <Box sx={{ paddingY: '0px' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'changepass' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('changepass');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'changepass' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    Change Password
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'change_notification' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('change_notification');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color:
                                                            activeTab == 'change_notification'
                                                                ? 'rgba(30, 124, 136, 1)'
                                                                : 'rgba(0, 0, 0, 0.87)',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    Change Notifications
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'change_homepage' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('change_homepage');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color:
                                                            activeTab == 'change_homepage'
                                                                ? 'rgba(30, 124, 136, 1)'
                                                                : 'rgba(0, 0, 0, 0.87)',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    Change Home Page
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
            <Grid md={8} sm={12} item sx={{ borderLeft: { md: '1px solid rgba(0, 0, 0, 0.17)' }, height: '100%', overFlowY: 'scroll' }}>
                <TabSelector activeTab={activeTab} tabs={tabs} />
            </Grid>
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Settings;
