import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, Box } from '@mui/material';

// project import
import { activeItem, leftSideDrawerOpen, setScrollToTop } from 'store/reducers/frontMenu';
import MenuStats from 'components/MenuStats';
import axios from 'lib/axios';
import UnseenCount from 'components/UnseenCount';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level, setOpen }) => {
    const [unseenCount, setUnseenCount] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.frontMenu);

    const { scrollToTop } = useSelector((state) => state.frontMenu);

    const { drawerOpen, openItem } = menu;
    // let drawerOpen = true;
    // let openItem = [];
    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch(activeItem({ openItem: [id] }));
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
        // eslint-disable-next-line
    }, []);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    useEffect(() => {
        const unseenCountFetch = async () => {
            setIsLoading(true);
            try {
                const res = await axios.post('notifications/get-unseen-count', {});

                if (res.status === 200) {
                    setIsLoading(false);
                    setUnseenCount(res.data.stats);
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };
        unseenCountFetch();
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => {
                dispatch(setScrollToTop(!scrollToTop));
                dispatch(leftSideDrawerOpen(false));
                itemHandler(item.id);
            }}
            selected={isSelected}
            sx={{
                zIndex: 0,
                // pl: drawerOpen ? `${level * 28}px` : 1.5,
                p: '5px',
                px: '20px',

                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.light',
                        background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                        color: 'primary.main',
                        borderLeft: `3px solid ${theme.palette.primary.main}`
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.light',
                        background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                        color: 'primary.main',
                        borderLeft: `3px solid ${theme.palette.primary.main}`
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Box display="flex" alignItems="center">
                            <Typography
                                variant="subtitle"
                                sx={{
                                    color: isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.87)',
                                    fontSize: { md: '16px', xs: '14px' }
                                }}
                            >
                                {item.title}
                            </Typography>
                            {!isLoading && <MenuStats item={item} />}
                            {isLoading ? (
                                <Box
                                    sx={{
                                        width: '15px',
                                        height: '15px',
                                        background: 'lightgray',
                                        borderRadius: '50%',
                                        marginLeft: '0.5rem'
                                    }}
                                />
                            ) : (
                                <UnseenCount item={item} data={unseenCount} />
                            )}
                        </Box>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
