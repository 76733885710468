/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid, Paper, Button, Modal } from '@mui/material';
import axios from 'lib/axios';
import avatar from '../../assets/images/users/avatar-2.png';
import { styled } from '@mui/material/styles';
import ViewProfileModal from 'components/modals/ViewProfileModal';
import RegisterUserSkeleton from 'components/skeleton/RegisterUserSkeleton';
import FootNote from 'components/FootNote';
import { Avatar, InputAdornment, OutlinedInput, FormControl } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import debouce from 'lodash.debounce';
import { convertDate } from 'Helpers';
import NotAvailableContent from 'components/NotAvailableContent';

const RegisteredUsers = () => {
    const [query, setQuery] = useState();
    const [employees, setEmployees] = useState([]);
    const [viewProfile, setViewProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchEmployees = async () => {
        try {
            setIsLoading(true);
            setEmployees((prev) => {
                prev = [];
                return prev;
            });
            let body = {
                company_id: JSON.parse(window.localStorage.getItem('authUser'))?.company_id,
                employee_type: '',
                name: query
            };
            await axios
                .post('companies/employee-list', body)
                .then(({ data }) => {
                    if (data.success) {
                        setIsLoading(false);
                        setEmployees((prev) => {
                            prev = data.employees;
                            return prev;
                        });
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };
    function handleChange(e) {
        setQuery(e.target.value);
    }
    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300);
    }, [employees]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });
    useEffect(() => {
        fetchEmployees();
    }, []);
    useEffect(() => {
        fetchEmployees();
    }, [query]);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'center',
        border: '1px solid rgba(30, 124, 136, 0.20)'
    }));

    return (
        <>
            <Box
                sx={{
                    height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - .5rem)' },
                    overflowY: 'scroll',
                    width: '100%'
                }}
            >
                <Box sx={{ marginBottom: '.6rem' }}>
                    {/* <Box item xs={12} md={6} display="flex" alignItems="center" sx={{ paddingTop: '.5rem' }}>
                        <FootNote />
                    </Box> */}
                    <Box
                        sx={{
                            marginBottom: { xs: '.5rem' }
                        }}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <FormControl>
                            <OutlinedInput
                                size="small"
                                id="header-search"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        sx={{
                                            color: '#000000',
                                            bgColor: '#FFFFFF',
                                            input: {
                                                '&::placeholder': {
                                                    textOverflow: 'ellipsis !important',
                                                    color: '#000000'
                                                }
                                            }
                                        }}
                                    >
                                        <SearchOutlined style={{ color: '#000000' }} />
                                    </InputAdornment>
                                }
                                aria-describedby="header-search-text"
                                inputProps={{
                                    'aria-label': 'weight'
                                }}
                                onChange={debouncedResults}
                                placeholder="Search an user"
                                variant="outlined"
                                sx={{ bgcolor: '#FFFFFF', color: '#000000', width: { md: '200px', xs: '100%' } }}
                            />
                        </FormControl>
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    {isLoading && (
                        <>
                            {new Array(6).fill('loading').map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <RegisterUserSkeleton />
                                </Grid>
                            ))}
                        </>
                    )}

                    {employees.length > 0 &&
                        employees.map((employee, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} md={4}>
                                    <Item>
                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                            <Avatar
                                                src={
                                                    employee.profile_image && employee.profile_image != '' ? employee.profile_image : avatar
                                                }
                                                alt={`${employee.first_name}`}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'cover',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </Grid>
                                        <Box sx={{ mt: '24px' }}>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '18px', sm: '20px' },
                                                    fontWeight: 'bold',
                                                    color: 'rgba(0, 0, 0, 0.87)',
                                                    wordBreak: 'break-word'
                                                }}
                                            >
                                                {employee.first_name} {employee.last_name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '14px', sm: '16px' },
                                                    color: '#1E7C88',
                                                    wordBreak: 'break-word'
                                                }}
                                            >
                                                {employee.job_title ? employee.job_title.title : 'N/A'}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column', md: 'column', lg: 'row' },
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mt: '12px',
                                                    gap: '5px'
                                                }}
                                            >
                                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: { xs: '14px', md: '16px' } }}>
                                                    Joined at
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
                                                    {convertDate(employee.createdAt)}
                                                </Typography>
                                            </Box>

                                            <Box mt={2}>
                                                <Button
                                                    onClick={() => {
                                                        setProfileData(employee);
                                                        setViewProfile(true);
                                                    }}
                                                    variant="contained"
                                                    sx={{
                                                        background: '#1E7C88',
                                                        color: 'white',
                                                        '&:hover': {
                                                            background: '#1E7C88'
                                                        },
                                                        fontSize: { xs: '14px', sm: '16px' }
                                                    }}
                                                >
                                                    View Profile
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Item>
                                </Grid>
                            );
                        })}
                </Grid>
                {!isLoading && employees.length == 0 && (
                    <Box mt={5}>
                        <NotAvailableContent />
                    </Box>
                )}
            </Box>
            {viewProfile && (
                <Modal
                    keepMounted
                    open={viewProfile}
                    onClose={() => setViewProfile(false)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <ViewProfileModal
                        setViewProfile={setViewProfile}
                        viewProfile={viewProfile}
                        profileData={profileData}
                        convertDate={convertDate}
                    />
                </Modal>
            )}
        </>
    );
};

export default RegisteredUsers;
