// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const movements = {
    id: 'movements',
    title: 'movements',
    type: 'group',
    children: [
        {
            id: 'movements',
            title: 'Movements & visits',
            type: 'item',
            url: '/dashboard/movements',
            breadcrumbs: false
        }
    ]
};

export default movements;
