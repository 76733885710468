import { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, Grid, Skeleton } from '@mui/material';
import appImg from '../../assets/images/icons/app1.jpg';
import axios from 'lib/axios';

const AppStore = () => {
    const [selectedType, setSelectedType] = useState('all');
    const [applications, setApplications] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchCategories = async () => {
        try {
            await axios.get(`/app-store/category`).then(({ data }) => {
                if (data.success) {
                    setCategories(() => data.categories);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    const fetchApps = async () => {
        try {
            const res = await axios.get(selectedType === 'all' ? '/app-store' : `/app-store/filter/${selectedType}`);

            if (res.data.success) {
                setApplications(res.data.applications);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchApps(), fetchCategories()]).then(() => {
            setLoading(() => false);
        });
    }, [selectedType]);
    console.log(categories);
    console.log(applications);
    return (
        <Box
            sx={{
                maxHeight: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Box>
                <Typography variant="h5" sx={{ fontWeight: '700' }}>
                    Apps Store
                </Typography>
            </Box>
            {/* types */}
            <Box display="flex" alignItems="center" gap={1} sx={{ maxWidth: '100%', overflowX: 'scroll' }}>
                <Box
                    marginY="1rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '30px;',
                        px: '16px',
                        py: '8px',
                        backgroundColor: selectedType === 'all' ? '#1E7C88' : '#DFE8EA',
                        color: selectedType === 'all' ? 'white' : 'black'
                    }}
                    onClick={() => setSelectedType('all')}
                >
                    <Typography>All</Typography>
                </Box>
                {loading
                    ? [...Array(4)].map((_, index) => (
                          <Skeleton key={index} variant="rounded" width={80} height={36} sx={{ borderRadius: '30px' }} />
                      ))
                    : categories.map((type, index) => {
                          return (
                              <Box
                                  key={index}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{
                                      cursor: 'pointer',
                                      borderRadius: '30px;',
                                      px: '16px',
                                      py: '8px',
                                      backgroundColor: selectedType === type.application_category_id ? '#1E7C88' : '#DFE8EA',
                                      color: selectedType === type.application_category_id ? 'white' : 'black'
                                  }}
                                  onClick={() => setSelectedType(type.application_category_id)}
                              >
                                  <Typography>{type.title}</Typography>
                              </Box>
                          );
                      })}
            </Box>
            {/* apps */}
            <Grid container spacing={2} sx={{ width: '100%' }}>
                {loading
                    ? [...Array(6)].map((_, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                              <Card sx={{ padding: '8px', width: '100%', height: '100%', borderRadius: '8px' }}>
                                  <Box display="flex" gap={1}>
                                      <Skeleton variant="rounded" width={96} height={96} />
                                      <Box sx={{ width: '100%' }}>
                                          <Skeleton variant="text" width="80%" height={24} />
                                          <Skeleton variant="text" width="60%" height={20} />
                                          <Box sx={{ mt: 2 }}>
                                              <Skeleton variant="rounded" width={80} height={32} />
                                          </Box>
                                      </Box>
                                  </Box>
                              </Card>
                          </Grid>
                      ))
                    : applications.map((app, index) => (
                          <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={index}
                              sx={{
                                  height: '100%'
                              }}
                          >
                              <Card
                                  key={index}
                                  sx={{
                                      padding: '8px',
                                      width: '100%',
                                      height: '100%',
                                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;',
                                      borderRadius: '8px'
                                  }}
                              >
                                  <Box display="flex" gap={1} sx={{ width: '100%', height: '100%' }}>
                                      {/* icon */}
                                      <img
                                          src={app.image}
                                          style={{
                                              width: '96px',
                                              height: '96px',
                                              objectFit: 'cover',
                                              borderRadius: '8px',
                                              background: 'lightgray'
                                          }}
                                          alt="app"
                                      />

                                      {/* name, type, btn */}
                                      <Box display="flex" flexDirection="column" justifyContent="space-between">
                                          <Box sx={{ height: '100%' }}>
                                              <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '16px' }}>
                                                  {app.title}
                                              </Typography>
                                              <Typography variant="body2" sx={{ fontSize: '13px', opacity: '60%' }}>
                                                  {app.appication_category.title}
                                              </Typography>
                                          </Box>
                                          <Box>
                                              <a href={app.redirect_link} target="_blank">
                                                  <Button
                                                      variant="contained"
                                                      size="small"
                                                      sx={{
                                                          backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                                          color: '#1E7C88',
                                                          '&:hover': {
                                                              cursor: 'pointer',
                                                              backgroundColor: '#1E7C88',
                                                              color: 'white'
                                                          }
                                                      }}
                                                  >
                                                      Open
                                                  </Button>
                                              </a>
                                          </Box>
                                      </Box>
                                  </Box>
                              </Card>
                          </Grid>
                      ))}
            </Grid>
        </Box>
    );
};
export default AppStore;
