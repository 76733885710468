/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Avatar, Box, Menu, MenuItem } from '@mui/material';
import { useModal } from 'context/ModalContext';
import AvatarImageViewer from 'components/modals/AvatarImageViewer';
import { Modal } from '@mui/material';
import MentionViewProfile from './modals/MentionViewProfile';

const UserAvatarComponent = ({ userData, style, offset }) => {
    const [openViewProfileModal, setOpenViewProfileModal] = useState(false);
    const sx = style ? style : { borderRadius: '50%', height: '3rem', width: '3rem' };
    const { isOpen, content, openModal, closeModal } = useModal();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Avatar
                src={`${userData?.profile_image ? userData?.profile_image : null}`}
                className="img-fit"
                alt={`${userData ? userData.first_name ?? userData.user_name : 'user'}`}
                style={sx}
                onClick={handleClick}
            />

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem
                    onClick={() => {
                        openModal(<AvatarImageViewer isOpen={true} closeModal={closeModal} data={userData} />);
                        handleClose();
                    }}
                >
                    View Photo
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setOpenViewProfileModal(true);
                        handleClose();
                    }}
                >
                    View Profile
                </MenuItem>
            </Menu>

            {openViewProfileModal && (
                <Modal
                    keepMounted
                    open={openViewProfileModal}
                    onClose={setOpenViewProfileModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <MentionViewProfile userId={userData.user_id} setOpenMentionProfile={setOpenViewProfileModal} />
                </Modal>
            )}
        </Box>
    );
};

export default UserAvatarComponent;
