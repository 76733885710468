import { Link, Box, Grid, Card, Typography, Menu, MenuItem } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { convertDateAndTime, processToUpper, timeDiffer } from 'Helpers';
import ThreeDot from '../../assets/static_images/threedot.svg';
import { useState } from 'react';

const PostHeader = ({ post, setOpenEditor, setOpenDeleteModal, canEdit, canDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box display="flex" alignItems="center">
            <UserAvatarComponent userData={post.user} />
            <Box sx={{ marginLeft: '1rem' }} flex="1">
                <Typography variant="h6">{`${post.user?.first_name} ${post.user.last_name}`}</Typography>
                <Box display="flex" alignItems="center" gap="0.5rem">
                    <Typography sx={{ opacity: '0.6', fontSize: '14px' }}>{`${
                        post.user?.job_title?.length > 0 ? post.user.job_title[0]?.title : 'N/A'
                    }`}</Typography>
                    <Typography variant="subtitle1" sx={{ opacity: '0.5', fontSize: '14px' }}>
                        {convertDateAndTime(post.createdAt)}
                    </Typography>
                </Box>
            </Box>
            {JSON.parse(window.localStorage.getItem('authUser')).user_id == post.user_id && canEdit && (
                <Grid item sm={1} className="cursor-pointer" display="flex" justifyContent="end" alignItems="center" onClick={handleClick}>
                    <img src={ThreeDot} alt="dot" style={{ marginLeft: 'auto' }} />
                </Grid>
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {canEdit && (
                    <MenuItem
                        onClick={() => {
                            setOpenEditor(true);
                            handleClose();
                        }}
                    >
                        Edit
                    </MenuItem>
                )}
                {canDelete && (
                    <MenuItem
                        onClick={() => {
                            setOpenDeleteModal(true);
                            handleClose();
                        }}
                    >
                        Delete
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default PostHeader;
