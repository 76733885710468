import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Card } from '@mui/material';
import threeDot from '../../assets/static_images/threedot.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Reacts from 'components/Reacts';
import Blank from '../../assets/static_images/blank.svg';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import LevelThreeCommentBox from './LevelThreeCommentBox';
const LevelThreeComment = ({ childComments, setParentComments, type, postOwnerId }) => {
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [openOption, setOpenOption] = useState(false);
    const [childComment, setChildComment] = useState(childComments);

    return (
        <>
            {/* level 3 comment */}
            {childComments.length > 0 &&
                childComments?.map((threeLevelComment, index) => {
                    return (
                        <LevelThreeCommentBox
                            comment={threeLevelComment}
                            setParentComments={setParentComments}
                            key={index}
                            type={type}
                            postOwnerId={postOwnerId}
                        />
                    );
                })}
        </>
    );
};

export default LevelThreeComment;
