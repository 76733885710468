import { Box, Card, Grid, Typography, FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import GraphChart from 'components/GraphChart';
import ModuleAccessWarning from 'components/shared/ModuleAccessWarning';
import axios from 'lib/axios';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import HasPermission from 'utils/HasPermission';

export const ClientDepartmentAnalytics = () => {
    const [loading, setLoading] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [filterValueDepartment, setFilterValueDepartment] = useState('');
    const [user, setUser] = useState(null);
    const [canRead, setCanRead] = useState(false);
    const [formalEvents, setFormalEvents] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Formal Events', data: arr }];
    });

    const [formalMeetings, setFormalMeetings] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Formal Meetings', data: arr }];
    });

    const [goals, setGoals] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Goals', data: arr }];
    });

    const [groups, setGroups] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Groups', data: arr }];
    });

    const [informalEvents, setInformalEvents] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Informal Events', data: arr }];
    });

    const [informalMeetings, setInformalMeetings] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Informal Meetings', data: arr }];
    });

    const [knowledge, setKnowledge] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Knowledge', data: arr }];
    });

    const [projects, setProjects] = useState(() => {
        let arr = Array(30).fill(0);
        return [{ name: 'Projects', data: arr }];
    });

    const [xAxisSeries, setXAxisSeries] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            if (i % 5 == 0) {
                arr.push(i.toString());
            } else {
                arr.push('');
            }
        }
        return arr;
    });
    const [filterDate, setFilterDate] = useState('last_month');

    const handleChange = async (event) => {
        setFilterDate(event.target.value);
    };
    const handleChangeDepartment = async (event) => {
        setFilterValueDepartment(event.target.value);
    };
    const fetchUser = async () => {
        try {
            const res = await axios.post(`/users/user`, {
                id: JSON.parse(localStorage.getItem('authUser'))?.user_id
            });
            setUser(res.data.data);
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    };
    const fetchDepartments = async () => {
        try {
            const res = await axios.get(`/departments`);
            if (res.data.success) {
                setDepartments(res.data.departments);
                // Set initial department value after fetching departments
                if (res.data.departments.length > 0) {
                    setFilterValueDepartment(res.data.departments[0].department_id);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    async function fetchSummaries() {
        try {
            setLoading(true);
            setFetched(false);
            const { data } = await axios.post(`/departments/summary`, {
                department_id: user.is_admin ? filterValueDepartment : user.department_id,
                filter_type: filterDate
            });

            if (data.success) {
                setFormalEvents((prev) => {
                    const updated = [...prev];
                    data.summary.formal_events.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.eventsCounts);
                    });
                    return updated;
                });

                setFormalMeetings((prev) => {
                    const updated = [...prev];
                    data.summary.formal_meetings.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.meetingsCounts);
                    });
                    return updated;
                });

                setGoals((prev) => {
                    const updated = [...prev];
                    data.summary.goals.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.goalsCounts);
                    });
                    return updated;
                });

                setGroups((prev) => {
                    const updated = [...prev];
                    data.summary.groups.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.groupsCounts);
                    });
                    return updated;
                });

                setInformalEvents((prev) => {
                    const updated = [...prev];
                    data.summary.informal_events.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.informalEventsCounts);
                    });
                    return updated;
                });

                setInformalMeetings((prev) => {
                    const updated = [...prev];
                    data.summary.informal_meetings.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.informalMeetingsCounts);
                    });
                    return updated;
                });

                setKnowledge((prev) => {
                    const updated = [...prev];
                    data.summary.knowledges.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.studyCounts);
                    });
                    return updated;
                });

                setProjects((prev) => {
                    const updated = [...prev];
                    data.summary.projects.forEach((item) => {
                        updated[0].data[parseInt(item.Date.slice(-2))] = parseInt(item.projectsCounts);
                    });
                    return updated;
                });
            }
            setFetched(true);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        HasPermission(setCanRead, 'Department', 'view', 'department');
        fetchUser();
        fetchDepartments();
    }, []);
    useEffect(() => {
        if (user && filterValueDepartment) {
            fetchSummaries();
        }
    }, [filterValueDepartment, filterDate, user]);

    console.log('canRead', filterValueDepartment);
    const LoadingSkeleton = () => (
        <Grid container spacing={2} mt={3}>
            {[...Array(8)].map((_, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                        <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
                        <Skeleton variant="rectangular" height={300} />
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <>
            {canRead ? (
                <Box sx={{ height: 'calc(100vh - 7.5rem)', overflowY: 'auto' }}>
                    <Card sx={{ p: 3 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4" sx={{ fontWeight: '600' }}>
                                Department Analytics
                            </Typography>

                            <Box display="flex" gap={2}>
                                {user && user.is_admin && (
                                    <FormControl sx={{ width: { md: '15rem', xs: '100%' } }}>
                                        <Select value={filterValueDepartment} onChange={handleChangeDepartment} displayEmpty>
                                            <MenuItem selected value="">
                                                <Typography>Select Departments</Typography>
                                            </MenuItem>
                                            {departments.map((department) => (
                                                <MenuItem key={department.department_id} value={department.department_id}>
                                                    {department.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <FormControl sx={{ width: { md: '10rem', xs: '100%' } }}>
                                    <Select value={filterDate} onChange={handleChange}>
                                        <MenuItem value="today">Today</MenuItem>
                                        <MenuItem value="last_month">Last Month</MenuItem>
                                        <MenuItem value="last_3_months">Last 3 Months</MenuItem>
                                        <MenuItem value="last_6_months">Last 6 Months</MenuItem>
                                        <MenuItem value="last_year">Last Year</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        {loading ? (
                            <LoadingSkeleton />
                        ) : (
                            fetched && (
                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Formal Events
                                            </Typography>
                                            <GraphChart
                                                series={formalEvents}
                                                categories={xAxisSeries}
                                                height={300}
                                                width={'100%'}
                                                type="bar"
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Formal Meetings
                                            </Typography>
                                            <GraphChart
                                                series={formalMeetings}
                                                categories={xAxisSeries}
                                                height={300}
                                                width={'100%'}
                                                type="bar"
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Goals
                                            </Typography>
                                            <GraphChart series={goals} categories={xAxisSeries} height={300} width={'100%'} type="line" />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Groups
                                            </Typography>
                                            <GraphChart series={groups} categories={xAxisSeries} height={300} width={'100%'} type="bar" />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Informal Events
                                            </Typography>
                                            <GraphChart
                                                series={informalEvents}
                                                categories={xAxisSeries}
                                                height={300}
                                                width={'100%'}
                                                type="bar"
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Informal Meetings
                                            </Typography>
                                            <GraphChart
                                                series={informalMeetings}
                                                categories={xAxisSeries}
                                                height={300}
                                                width={'100%'}
                                                type="bar"
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Knowledge
                                            </Typography>
                                            <GraphChart
                                                series={knowledge}
                                                categories={xAxisSeries}
                                                height={300}
                                                width={'100%'}
                                                type="line"
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Card sx={{ p: 2, borderRadius: '1rem', border: '1px solid #1e7c88', boxShadow: 0 }}>
                                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                                                Projects
                                            </Typography>
                                            <GraphChart series={projects} categories={xAxisSeries} height={300} width={'100%'} type="bar" />
                                        </Card>
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </Card>
                </Box>
            ) : (
                <ModuleAccessWarning />
            )}
        </>
    );
};
