import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Button, Modal, Box } from '@mui/material';
import AppStoreDeleteModal from 'components/modals/AppStoreDeleteModal';
import AppStoreModal from 'components/modals/AppStoreModal';

export default function AppStoreTable({ applications, setApplications }) {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedApp, setSelectedApp] = useState({});
    return (
        <Box>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Url</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {applications.length > 0 && (
                        <TableBody>
                            {applications.map((row, index) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell>{row.appication_category.title}</TableCell>
                                    <TableCell>{row.redirect_link}</TableCell>

                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setSelectedApp(row);
                                                setOpenEditModal(true);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{ ml: 1 }}
                                            onClick={() => {
                                                setSelectedId(row.id);
                                                setOpenDeleteModal(true);
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {openDeleteModal && (
                <Modal
                    keepMounted
                    open={openDeleteModal}
                    onClose={setOpenDeleteModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <AppStoreDeleteModal
                        setOpenDeleteModal={setOpenDeleteModal}
                        setApplications={setApplications}
                        selectedId={selectedId}
                    />
                </Modal>
            )}
            {openEditModal && (
                <Modal
                    keepMounted
                    open={openEditModal}
                    onClose={setOpenEditModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <AppStoreModal setOpenModal={setOpenEditModal} setApplications={setApplications} selectedApp={selectedApp} />
                </Modal>
            )}
        </Box>
    );
}
