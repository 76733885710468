// assets
import { UserOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UserOutlined,
    MedicineBoxOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

let children = [
    {
        id: 'My Profile',
        title: 'My Profile',
        type: 'item',
        url: '/profile/null',
        breadcrumbs: false
    },
    {
        id: 'My Group',
        title: 'My Group',
        type: 'item',
        url: '/group',
        breadcrumbs: false
    }
    // {
    //     id: 'app_store',
    //     title: 'My Applications',
    //     type: 'item',
    //     url: '/app-store',
    //     breadcrumbs: false
    // }
];

const Profile = {
    id: `profiletab`,
    title: JSON.parse(window.localStorage.getItem('authUser'))?.username,
    type: 'group',
    icon: icons.UserOutlined,
    children: children
};

export default Profile;
