/* eslint-disable prettier/prettier */

import Navigation from './Navigation';
import { Grid } from '@mui/material';

// ==============================|| DRAWER CONTENT ||============================== //

const LeftSide = () => (
    <Grid container sx={{ flexShrink: { md: 0 } }}>
        {/* <SimpleBar
                sx={{
                    '& .simplebar-content': {
                        display: 'flex',
                        flexDirection: 'column',
                    }
                }}
            > */}
        <Grid
            item
            sm={12}
            sx={{
                overflowY: 'scroll',
                border: '1px solid #1E7C8840',
                borderRadius: '8px',
                backgroundColor: '#fff',
                height: 'calc(100vh - 7.5rem)'
            }}
        >
            <Navigation />
        </Grid>
        {/* <NavCard /> */}
        {/* </SimpleBar> */}
    </Grid>
);

export default LeftSide;
