/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import CompanyIcon from '../../assets/static_images/company.svg';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VoiceViewer from 'components/modals/VoiceViewer';
import { Box } from '@mui/material';
import RightSideSkeleton from 'components/skeleton/RightSideSkeleton';
import MiniPost from 'components/post/MiniPost';
import { socket } from 'socket';

const TopPosts = ({ type }) => {
    const [openViewer, setOpenViewer] = useState(false);
    const [selected, setSelected] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);

    function fetchPosts() {
        const formData = new FormData();
        formData.append('company_id', JSON.parse(window.localStorage.getItem('authUser'))?.company_id);
        formData.append('offset', offset);
        formData.append('limit', limit);
        if (type === 'post') {
            formData.append('is_social_post', true);
        } else if (type === 'leader_voice') {
            formData.append('is_leader_voice', true);
        } else if (type === 'voice') {
            formData.append('is_voice_post', true);
        }

        setIsLoading(true);
        axios
            .post('/social-posts/most-liked', formData)
            .then(({ data }) => {
                setIsLoading(false);
                if (data.success) {
                    if (data.posts.length > 0) {
                        setPosts((prev) => [...prev, ...data.posts]);
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }

    useEffect(() => {
        if (offset === 0) {
            setPosts([]);
        }
        fetchPosts();
    }, [type, offset]);

    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('post_created', (post) => {
            setPosts((prev) => [post, ...prev]);
        });

        socket.on('post_deleted', (post) => {
            setPosts((prev) => {
                prev = prev
                    .filter((item) => {
                        if (item.post_id != post.post_id) {
                            return item;
                        }
                    })
                    .filter((element) => element !== null && element !== '' && element !== 'null');
                return prev;
            });
        });
    }

    return (
        <Grid sm={12} container>
            <Grid item sm={12} display="flex" alignItems="center" sx={{ paddingX: '0px', width: '100%' }}>
                <Box
                    sx={{
                        borderRadius: { md: '10px', xs: '0px' },
                        height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - .5rem)' },
                        overflowY: 'scroll',
                        border: '1px solid #1E7C8840',
                        backgroundColor: '#fff',
                        width: '100%'
                    }}
                >
                    <CardContent sx={{ paddingX: '0px' }}>
                        <Grid
                            container
                            sm={12}
                            display="flex"
                            alignItems="center"
                            spacing={1}
                            sx={{ borderBottom: '1px solid #1E7C8840', padding: '0 1rem 0.5rem' }}
                        >
                            <Grid item display="flex" alignItems="center" justifyContent="center">
                                <img src={CompanyIcon} alt="icon" />
                            </Grid>
                            <Grid item>
                                <Typography color="primary">Most Popular Post</Typography>
                            </Grid>
                        </Grid>
                        <Box marginY="1rem">
                            {isLoading
                                ? Array.from({ length: 10 }, (_, index) => <RightSideSkeleton key={index} />)
                                : posts.map((post, index) => (
                                      <MiniPost key={`mini-${index}`} post={post} setSelected={setSelected} setOpenViewer={setOpenViewer} />
                                  ))}

                            {posts.length > 5 ? (
                                <Box display="flex" justifyContent="center" mb={2}>
                                    <Button
                                        variant="contained"
                                        sx={{ borderRadius: '50px' }}
                                        onClick={() => {
                                            setOffset(0);
                                        }}
                                    >
                                        View Less
                                    </Button>
                                </Box>
                            ) : (
                                posts.length === 5 && (
                                    <Box display="flex" justifyContent="center" mb={2}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: '50px' }}
                                            onClick={() => {
                                                setOffset((prev) => prev + limit);
                                            }}
                                        >
                                            View More
                                        </Button>
                                    </Box>
                                )
                            )}
                        </Box>
                    </CardContent>
                </Box>
            </Grid>
            {openViewer && (
                <Modal
                    keepMounted
                    open={openViewer}
                    onClose={() => setOpenViewer(false)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid
                        item
                        sm={12}
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100vw', height: '100vh', paddingX: '1.5rem' }}
                    >
                        <VoiceViewer post={selected} setOpenViewer={setOpenViewer} model="post" />
                    </Grid>
                </Modal>
            )}
        </Grid>
    );
};

export default TopPosts;
