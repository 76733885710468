import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TabSelector from 'components/TabSelector';
import Likes from './activities/Likes';
import { Chip, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Alert } from '@mui/lab';
import { Link, InputLabel, OutlinedInput, Snackbar } from '@mui/material';
import Comments from './activities/Comments';
import Posts from './activities/Posts';
import News from './activities/News';
import Events from './activities/Events';
import { Box } from '@mui/material';
import NewsFeed from './activities/NewsFeed';
import PasswordUpdate from './activities/PasswordUpdate';
import ProfileUpdate from './activities/ProfileUpdate';
import HomePageUpdate from './activities/HomePageUpdate';
import MeetingActivity from './activities/MeetingActivity';
import MovementActivity from './activities/MovementActivity';
import KnowledgeActivity from './activities/KnowledgeActivity';
import VoiceActivity from './activities/VoiceActivity';
import ProjectActivity from './activities/ProjectActivity';
import CompletedTaskActivity from './activities/CompletedTaskActivity';
import AddedTaskActivity from './activities/AddedTaskActivity';
const Activity = () => {
    const theme = useTheme();
    const [userData, setUserDate] = useState();
    const [activeTab, setActiveTab] = useState('newsFeed');
    const [openSnack, setShowSnack] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    function handleSnackClose() {
        setShowSnack(false);
    }
    const [tabs, setTabs] = useState({
        likes: (
            <Likes
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        comments: (
            <Comments
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        posts: (
            <Posts
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        news: (
            <News
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        events: (
            <Events
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        movement: (
            <MovementActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        newsFeed: <NewsFeed />,
        passwordUpdate: (
            <PasswordUpdate
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        profileUpdate: (
            <ProfileUpdate
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        homePageUpdate: (
            <HomePageUpdate
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        meetings: (
            <MeetingActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        knowledge: (
            <KnowledgeActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        voice: (
            <VoiceActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        project: (
            <ProjectActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        completed_task: (
            <CompletedTaskActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        ),
        added_task: (
            <AddedTaskActivity
                showSnack={(isError, message) => {
                    setSnackOptions({ isError, message });
                    setShowSnack(true);
                }}
            />
        )
    });
    useEffect(() => {
        axios
            .post(`/users/user`, {
                id: JSON.parse(window.localStorage.getItem('authUser'))?.user_id.toString()
            })
            .then(({ data }) => {
                setUserDate(data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <Grid sm={12} container>
            <Grid
                sm={3}
                item
                sx={{
                    borderRight: { md: '1px solid rgba(0, 0, 0, 0.17)', xs: 'none' },
                    height: { md: '100vh', xs: 'auto' }
                    // background: 'red'
                }}
            >
                {userData ? (
                    <Grid
                        container
                        sm={12}
                        display="flex"
                        spacing={3}
                        sx={{
                            flexDirection: { md: 'column', xs: 'row' },
                            paddingTop: { md: '2rem', xs: '1rem' },
                            width: { md: '100%', xs: '100vw' },
                            overflowX: 'scroll'
                        }}
                    >
                        <Grid item sm={12} sx={{ display: { md: 'block', xs: 'none' } }}>
                            <Grid container sm={12} display="flex" direction="column">
                                <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                    <Typography sx={{ fontWeight: '700' }} variant="h5" color="primary">
                                        My Activities
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box>
                            <Box
                                display="flex"
                                alignItems="start"
                                sx={{
                                    flexDirection: { md: 'column', xs: 'row' },
                                    paddingTop: '1rem',
                                    paddingX: '0px',
                                    width: { md: 'auto', xs: '100vw' }
                                }}
                            >
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'newsFeed' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main',
                                                width: '100%'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('newsFeed');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'newsFeed' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    News Feed
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'likes' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main',
                                                width: '100%'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('likes');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'likes' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Likes
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'comments' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('comments');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'comments' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Comments
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'posts' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('posts');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'posts' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Social Posts
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'news' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('news');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'news' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    News Posts
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'events' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('events');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'events' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Events
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'meetings' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('meetings');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'meetings' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Meetings
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'movement' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('movement');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'movement' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Movement
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'knowledge' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('knowledge');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'knowledge' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Knowledge
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'voice' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('voice');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'voice' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Voice
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'project' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('project');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'project' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Project
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'completed_task' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('completed_task');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color:
                                                            activeTab == 'completed_task' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Completed Task
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'added_task' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('added_task');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color: activeTab == 'added_task' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Added Task
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'homePageUpdate' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('homePageUpdate');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color:
                                                            activeTab == 'homePageUpdate' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Homepage Update
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'profileUpdate' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('profileUpdate');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color:
                                                            activeTab == 'profileUpdate' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Profile Update
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ paddingY: '0px', width: '100%', whiteSpace: 'nowrap' }}>
                                    <ListItemButton
                                        item
                                        selected={activeTab == 'passwordUpdate' ? true : false}
                                        sx={{
                                            '&.Mui-selected': {
                                                bgcolor: 'primary.light',
                                                background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                color: 'primary.main'
                                            }
                                        }}
                                        onClick={() => {
                                            setActiveTab('passwordUpdate');
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        color:
                                                            activeTab == 'passwordUpdate' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                >
                                                    Password Update
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
            <Grid sm={8} item sx={{ height: '100vh', overflowY: 'scroll', marginTop: '1rem' }}>
                <TabSelector activeTab={activeTab} tabs={tabs} />
            </Grid>
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Activity;
