import { Modal, Box, Card, Button, OutlinedInput, FormControl, InputAdornment, Typography } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import AppStoreTable from './AppStoreTable';
import { useEffect, useState } from 'react';
import AppStoreModal from 'components/modals/AppStoreModal';
import axios from 'lib/axios';
import AppCategoryModal from 'components/modals/AppCategoryModal';
import AppCategoryTable from './AppCategoryTable';
import { toast } from 'sonner';
const CreateApp = () => {
    const [openModal, setOpenModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [applications, setApplications] = useState([]);
    const [appListTable, setAppListTable] = useState(true);

    useEffect(() => {
        const fetchApps = async () => {
            try {
                const res = await axios.get('/app-store');
                console.log(res);
                if (res.data.success) {
                    setApplications(res.data.applications);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.response.data.message);
            }
        };
        const fetchCategories = async () => {
            try {
                const res = await axios.get('/app-store/category');
                console.log(res);
                if (res.data.success) {
                    setCategories(res.data.categories);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.response.data.message);
            }
        };
        fetchApps();
        fetchCategories();
    }, []);
    return (
        <>
            <Card sx={{ padding: '1rem' }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{ '&:hover': { background: '#3B8D97' } }}
                                onClick={() => setOpenModal(true)}
                            >
                                <Typography variant="h5" sx={{ mr: 1 }}>
                                    +
                                </Typography>
                                Create App
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{ '&:hover': { background: '#3B8D97' } }}
                                onClick={() => setOpenCategoryModal(true)}
                            >
                                <Typography variant="h5" sx={{ mr: 1 }}>
                                    +
                                </Typography>
                                Create Category
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{ '&:hover': { background: '#3B8D97' }, py: 1.4 }}
                                onClick={() => setAppListTable(!appListTable)}
                            >
                                {appListTable ? 'Show Category List' : 'Show App List'}
                            </Button>
                        </Box>
                        <Box>
                            <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                                <OutlinedInput
                                    sx={{ width: '100%' }}
                                    id="header-search"
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                                            <SearchOutlined />
                                        </InputAdornment>
                                    }
                                    aria-describedby="header-search-text"
                                    inputProps={{
                                        'aria-label': 'weight'
                                    }}
                                    placeholder="Search Here..."
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    {appListTable ? (
                        <AppStoreTable applications={applications} setApplications={setApplications} />
                    ) : (
                        <AppCategoryTable categories={categories} setCategories={setCategories} />
                    )}
                </Box>
            </Card>
            {openModal && (
                <Modal
                    keepMounted
                    open={openModal}
                    onClose={setOpenModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <AppStoreModal openModal={openModal} setOpenModal={setOpenModal} setApplications={setApplications} />
                </Modal>
            )}
            {openCategoryModal && (
                <Modal
                    keepMounted
                    open={openCategoryModal}
                    onClose={setOpenCategoryModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <AppCategoryModal openModal={openCategoryModal} setOpenModal={setOpenCategoryModal} setCategories={setCategories} />
                </Modal>
            )}
        </>
    );
};
export default CreateApp;
