// assets
import { DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const children = [
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'user',
                title: 'User',
                type: 'item',
                url: '/dashboard/user-analytics',
                breadcrumbs: false
            },
            // {
            //     id: 'department',
            //     title: 'Department',
            //     type: 'item',
            //     url: '/dashboard/deparment-analytics',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'division',
            //     title: 'Division',
            //     type: 'item',
            //     url: '/dashboard/deparment-analytics',
            //     breadcrumbs: false
            // },
            {
                id: 'organization',
                title: 'Organization',
                type: 'item',
                url: '/dashboard/organization-analytics',
                breadcrumbs: false
            }
        ]
    }
];
const analytics = {
    id: 'analytics',
    title: 'Analytics',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default analytics;
