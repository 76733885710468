import React, { useState } from 'react';
import { Box, Grid, Typography, Modal } from '@mui/material';
import ClockIcon from '../../assets/static_images/clock.svg';
import CalanderIcon from '../../assets/static_images/calander.svg';
import UserAvatarComponent from 'components/UserAvatarComponent';
import EventDetails from 'components/modals/EventDetails';

const EventByDate = ({ event }) => {
    console.log(event);
    const [date, setDate] = useState(new Date(new Date(event?.due_data).toDateString() + ' ' + event?.due_time));
    const [openVenue, setOpenVenue] = useState(false);
    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = date / 1000; // use UNIX timestamp in seconds
    const remainingTime = endTime - stratTime;
    const [timeColor, setTimeColor] = useState(remainingTime <= 0 ? 'error' : 'primary');
    const [eventUsers, setEventUsers] = useState(
        event?.event_users &&
            event?.event_users?.map((item) => {
                return { ...item.user, is_organizer: item.is_organizer, rsvp: item.rsvp };
            })
    );
    const [response, setResponse] = useState(() => {
        return eventUsers.filter((item) => {
            if (item.user_id == JSON.parse(window.localStorage.getItem('authUser'))?.user_id) {
                return item;
            }
        })[0]?.rsvp;
    });

    async function submitResponse(response) {
        axios
            .post(`/events/response`, {
                event_id: event.event_id,
                response: response
            })
            .then(({ data }) => {
                if (data.success) {
                    setAttendenceCount((prev) => prev + 1);
                    setResponse(data.response);
                }
            })
            .catch((err) => console.log(err));
    }
    return (
        <Box sx={{ mt: '20px', borderBottom: '1px solid #EAE8E8', pb: '10px' }}>
            {/* user avatar and name and role */}
            <Box display="flex" alignItems="center">
                <UserAvatarComponent userData={event.user} />
                <Box ml={1}>
                    <Box>
                        <Typography
                            sx={{ fontSize: '16px', fontWeight: '700' }}
                        >{`${event.user?.first_name} ${event.user.last_name}`}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: '12px', opacity: '70%' }}>{`${event.user?.role.role_type} `}</Typography>
                    </Box>
                </Box>
            </Box>
            {/* title */}
            <Box mt={2}>
                <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '20px' }} mb={2}>
                    {event.post_title}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                {/* date and time */}
                <Box display="flex" alignItems="center">
                    {/* date */}
                    <Box container display="flex" alignItems="center" sx={{ padding: '0px', gap: '5px' }}>
                        <Box item display="flex" alignItems="center" justifyContent="center">
                            <img src={CalanderIcon} alt="calandericon" />
                        </Box>
                        <Box item>
                            <Typography color="primary" sx={{ fontWeight: '700' }}>{`${new Date(event.due_data)
                                .toString()
                                .substring(3, 16)}`}</Typography>
                        </Box>
                    </Box>
                    {/* time */}
                    <Box display="flex" alignItems="center" sx={{ gap: '5px' }} ml={2}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <img src={ClockIcon} alt="clockicon" style={{ width: '16px', height: '16px' }} />
                        </Box>
                        <Box>
                            <Typography color="primary" sx={{ fontWeight: '700' }}>
                                {`${
                                    parseInt(date.getHours()) > 12
                                        ? `${parseInt(date.getHours()) - 12 < 10 ? '0' : ''}${parseInt(date.getHours()) - 12}`
                                        : date.getHours()
                                } : ${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color="primary" sx={{ fontWeight: '700' }}>
                                {`${parseInt(date.getHours()) > 12 ? 'pm' : 'am'}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {/* venue and more */}
                <Typography onClick={() => setOpenVenue(true)} sx={{ opacity: '.5', cursor: 'pointer' }}>
                    Venue & more
                </Typography>
            </Box>
            {openVenue && (
                <Modal
                    keepMounted
                    open={openVenue}
                    onClose={setOpenVenue}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <EventDetails
                        event={event}
                        eventUsers={eventUsers}
                        timeColor={timeColor}
                        setShowDetails={setOpenVenue}
                        remainingTime={remainingTime}
                        date={date}
                        submitResponse={submitResponse}
                        response={response}
                    />
                </Modal>
            )}
        </Box>
    );
};

export default EventByDate;
