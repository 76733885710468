import { useState } from 'react';
import { Button, Box, Modal, Card, Badge, CardContent, OutlinedInput, Typography, FormControl, Select, MenuItem } from '@mui/material';
import axios from 'lib/axios';
import { toast } from 'sonner';

export default function ModuleAndCategoryCreateModal({ isOpen, handleClose, type, moduleCategoriesList, setModuleCategoriesList }) {
    const [moduleCategory, setModuleCategory] = useState('');
    const [moduleName, setModuleName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            const res = await axios.post(
                type === 'create_category' ? '/module-category' : '/module',
                type === 'create_category'
                    ? { title: moduleCategory }
                    : {
                          title: moduleName,
                          module_category_id: selectedCategory
                      }
            );
            console.log(res.data);
            if (res.data.success && type === 'create_category') {
                toast.success('Module Category Created Successfully');
                setModuleCategoriesList([...moduleCategoriesList, { module: res.data.data }]);
                handleClose();
            }
            if (res.data.success && type === 'create_module') {
                toast.success('Module Created Successfully');
                // setModuleCategoriesList([...moduleCategoriesList, res.data.data]);
                handleClose();
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    return (
        <Modal
            keepMounted
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box container display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                <Card
                    sx={{
                        padding: '.5rem',
                        borderRadius: '10px',
                        width: { md: '30vw', xs: '90vw' },
                        marginTop: '6rem',
                        maxHeight: '90vh',
                        position: 'relative'
                    }}
                >
                    <Badge
                        color="primary"
                        badgeContent={'X'}
                        className="cursor-pointer"
                        onClick={handleClose}
                        sx={{ marginLeft: 'auto', marginRight: '1rem', position: 'absolute', top: '1.5rem', right: '1rem' }}
                    ></Badge>
                    <CardContent>
                        {/* cerate category */}
                        {type === 'create_category' && (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="label" sx={{ fontWeight: 'bold' }}>
                                    Create Module Category
                                </Typography>
                                <OutlinedInput placeholder="Module Category" onChange={(e) => setModuleCategory(e.target.value)} />
                            </Box>
                        )}
                        {type === 'create_module' && (
                            <>
                                {/* cerate module */}
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="label" sx={{ fontWeight: 'bold' }}>
                                        Create Module
                                    </Typography>
                                    <OutlinedInput placeholder="Create Module" onChange={(e) => setModuleName(e.target.value)} />
                                </Box>
                                {/* select category */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                                    <Typography variant="label" sx={{ fontWeight: 'bold' }}>
                                        Select Category
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedCategory}
                                            onChange={handleChange}
                                        >
                                            {moduleCategoriesList.map((category) => (
                                                <MenuItem key={category.module_category_id} value={category.module_category_id}>
                                                    {category.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </>
                        )}
                        <Box>
                            <Button onClick={handleSubmit} variant="contained" sx={{ width: '100%', marginTop: '1rem' }}>
                                Create
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
}
