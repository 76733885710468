import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Card } from '@mui/material';
import LevelThreeComment from './LevelThreeComment';
import ChildCommentBox from './ChildCommentBox';
const ChildComment = ({ childComments, setParentComments, type, postOwnerId }) => {
    const [childComment, setChildComment] = useState(childComments);

    return (
        <>
            {childComments.length > 0 &&
                childComments?.map((comment, index) => {
                    return (
                        <Box key={index}>
                            <ChildCommentBox
                                setParentComments={setParentComments}
                                comment={comment}
                                key={index}
                                setChildComment={setChildComment}
                                type={type}
                                postOwnerId={postOwnerId}
                            />
                        </Box>
                    );
                })}
        </>
    );
};

export default ChildComment;
