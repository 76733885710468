// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
const children = [
    {
        id: 'organizationStructure',
        title: 'Organization Structure',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'department',
                title: 'Departments',
                type: 'item',
                url: '/dashboard/department',
                breadcrumbs: false
            },
            {
                id: 'departmentDivision',
                title: 'Division',
                type: 'item',
                url: '/dashboard/department-division',
                breadcrumbs: false
            },
            {
                id: 'role',
                title: 'Role Permissions', //old is job role
                type: 'item',
                url: '/dashboard/roles',
                breadcrumbs: false
            },
            {
                id: 'job-title',
                title: 'Job Title', //old is job role
                type: 'item',
                url: '/dashboard/job-title',
                breadcrumbs: false
            },

            // {
            //     id: 'divisions',
            //     title: 'Divisions',
            //     type: 'item',
            //     url: '/dashboard/divisions',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'districts',
            //     title: 'Districts',
            //     type: 'item',
            //     url: '/dashboard/districts',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'locations',
            //     title: 'Locations',
            //     type: 'item',
            //     url: '/dashboard/locations',
            //     breadcrumbs: false
            // },
            {
                id: 'permission',
                title: 'Permission List',
                type: 'item',
                url: '/dashboard/permissions',
                breadcrumbs: false
            },
            {
                id: 'module-permission',
                title: 'Module Permissions',
                type: 'item',
                url: '/dashboard/module-permissions',
                breadcrumbs: false
            }
        ]
    }
];
const OrganizationStructure = {
    id: 'organizationStructure',
    title: 'Organization Structure',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default OrganizationStructure;
