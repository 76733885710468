import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Stack } from '@mui/material';
import axios from 'lib/axios';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';
import { toast } from 'sonner';

const DeleteModal = ({ setOpenDeleteModal, url, id, showSnack, setPosts, model = 'post', reload, setReload }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const onConfirm = () => {
        setLoading(true);
        dispatch(activeLoader(true));
        axios
            .post(`${url}`, { id: id })
            .then(({ data }) => {
                if (data.success) {
                    dispatch(activeLoader(false));
                    if (showSnack) {
                        toast.success(`${data.message}`);
                    }

                    if (setPosts) {
                        setTimeout(
                            () =>
                                setPosts((posts) => [
                                    ...posts.filter((post) => {
                                        if (model == 'post') {
                                            if (post.post_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'news') {
                                            if (post.news_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'event') {
                                            if (post.event_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'policy') {
                                            if (post.policy_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'task') {
                                            if (post.task_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'study') {
                                            if (post.self_study_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'meeting') {
                                            if (post.meeting_id != id) {
                                                return post;
                                            }
                                        }
                                    })
                                ]),
                            650
                        );
                    }

                    handleClose();
                }
            })
            .catch((err) => {
                dispatch(activeLoader(false));
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal open={true} onClose={handleClose} aria-labelledby="delete-modal" aria-describedby="delete-confirmation-modal">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                    outline: 'none'
                }}
            >
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Delete Confirmation
                </Typography>

                <Typography variant="body1" sx={{ mb: 3 }}>
                    Are you sure you want to delete this? This action cannot be undone.
                </Typography>

                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onConfirm}
                        disabled={loading}
                        sx={{
                            bgcolor: '#d32f2f',
                            '&:hover': {
                                bgcolor: '#b71c1c'
                            }
                        }}
                    >
                        {loading ? 'Deleting...' : 'Delete'}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default DeleteModal;
