// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    openComponent: 'buttons',
    drawerOpen: true,
    componentDrawerOpen: true,
    rightSideDrawerOpen: false,
    openLeftSidebar: false,
    formalEventType: true,
    selectType: 'news',
    meetingIsFormal: true,
    scrollToTop: false
};

// ==============================|| SLICE - MENU ||============================== //

const frontMenu = createSlice({
    name: 'frontMenu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },

        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },

        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        },

        rightSideDrawer(state, action) {
            state.rightSideDrawerOpen = action.payload;
        },
        leftSideDrawerOpen(state, action) {
            state.openLeftSidebar = action.payload;
        },
        formalEventTypeAction(state, action) {
            state.formalEventType = action.payload;
        },
        selectNewsNoticeType(state, action) {
            state.selectType = action.payload;
        },
        setMeetingIsFormal(state, action) {
            state.meetingIsFormal = action.payload;
        },
        setScrollToTop(state, action) {
            state.scrollToTop = action.payload;
        }
    }
});

export default frontMenu.reducer;

export const {
    activeItem,
    activeComponent,
    openDrawer,
    openComponentDrawer,
    rightSideDrawer,
    leftSideDrawerOpen,
    formalEventTypeAction,
    selectNewsNoticeType,
    setMeetingIsFormal,
    setScrollToTop
} = frontMenu.actions;
