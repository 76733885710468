import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import feedbackIcon from '../../../../../assets/static_images/feedback.svg';
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //
import HasPermission from 'utils/HasPermission';
import CreateFeedback from 'pages/client-feedback/index';
const ProfileTab = ({ handleLogout }) => {
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const theme = useTheme();
    const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
    const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [canSee, setCanSee] = useState(false);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    useEffect(() => {
        HasPermission(setCanSee, 'User', 'create', 'create_user');
    }, []);
    return (
        <>
            <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
                {canSee && (
                    <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => {
                            location.replace('/dashboard');
                        }}
                    >
                        <ListItemIcon>
                            <EditOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                )}

                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => {
                        location.replace('/profile/null');
                    }}
                >
                    <ListItemIcon>
                        <UserOutlined />
                    </ListItemIcon>
                    <ListItemText primary="View Profile" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 2} onClick={handleOpenFeedbackModal}>
                    <ListItemIcon>
                        <img src={feedbackIcon} style={{ width: '20px', height: '20px' }} alt="feedback" />
                    </ListItemIcon>
                    <ListItemText primary="Feedback" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 3} onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
            {openFeedbackModal && <CreateFeedback open={openFeedbackModal} handleClose={handleCloseFeedbackModal} />}
        </>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
