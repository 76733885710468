import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import Bell from '../../../../assets/static_images/bell.svg';
// assets
import { BellOutlined, CloseOutlined, GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'lib/axios';
import NotificationItem from 'components/Notification';
import { socket } from '../../../../socket';
import NotifyCall from 'components/modals/NotifyCall';
import { useModal } from '../../../../context/ModalContext';
// sx styles

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //
import Modal from 'components/modals/Modal';
import NoticeNotify from 'components/modals/NoticeNotify';
import NotificationList from 'components/NotificationList';
const Notification = () => {
    const { isOpen, content, openModal, closeModal } = useModal();
    const [isConnected, setIsConnected] = useState(socket.connected);
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const [notifications, setNotifications] = useState([]);
    const [openCall, setOpenCall] = useState(false);
    const iconBackColorOpen = 'primary';
    const iconBackColor = 'primary';
    function onConnect() {
        let data = { user_id: JSON.parse(window.localStorage.getItem('authUser'))?.user_id, socketId: socket.id };
        socket.emit('setSocketId', data);
        setIsConnected(true);
    }
    const [isOpenModal, setIsOpenModal] = useState(false);
    useEffect(() => {
        if (!notifications.length > 0) {
            fetchNotifications();
        }

        if (!isConnected) {
            socket.connect();
        }
        socket.on('connect', onConnect);
        socket.on('newNotification', () => {
            fetchNotifications();
        });
        socket.on('newCallMesasge', (data) => {
            if (data.sender_id !== JSON.parse(window.localStorage.getItem('authUser'))?.user_id) {
                setIsOpenModal(true);
                openModal(<NotifyCall isOpen={true} closeModal={closeModal} data={data} />);
            }
        });
        socket.on('newNotice', (data) => {
            setIsOpenModal(true);
            openModal(<NoticeNotify isOpen={true} closeModal={closeModal} notice={data} />);
        });
    }, [socket]);
    const [unseenCount, setUnSeenCount] = useState(0);
    const [bellActive, setBellActive] = useState(false);
    async function fetchNotifications() {
        await axios
            .get('/notifications')
            .then(({ data }) => {
                if (data.success) {
                    setNotifications([]);
                    setNotifications(data.notifications);
                    data.notifications.map((notification) => {
                        if (!notification.seen) {
                            setBellActive(true);
                            setUnSeenCount((prev) => prev + 1);
                        }
                    });
                }
            })
            .catch((err) => console.log(err));
    }
    const [show, setShow] = useState(true);

    async function markNotificationsAsRead() {
        setShow(() => false);
        await axios
            .post('/notifications/mark-all-as-seen', {
                to_user_id: JSON.parse(window.localStorage.getItem('authUser'))?.user_id ?? null
            })
            .then(({ data }) => {
                if (data.success) {
                    setBellActive(() => false);
                    setUnSeenCount(() => 0);
                    setNotifications((prev) => {
                        return prev.map((notification) => {
                            notification.seen = true;
                            return notification;
                        });
                    });
                    setShow(true);
                }
            });
    }
    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                disableRipple
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                {bellActive ? (
                    <Badge badgeContent={unseenCount} color="error" sx={{ padding: '.2rem' }}>
                        <img src={Bell} alt="fsadf" />
                    </Badge>
                ) : (
                    <Badge badgeContent={''} sx={{ padding: '.2rem' }}>
                        <img src={Bell} alt="fsadf" />
                    </Badge>
                )}
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 420,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title={<Typography variant="h5">Notifications</Typography>}
                                    elevation={0}
                                    border={false}
                                    content={false}
                                    secondary={
                                        <Box display="flex" alignItems="center">
                                            {bellActive && (
                                                <Typography
                                                    className="cursor-pointer"
                                                    sx={{ fontSize: '13px' }}
                                                    onClick={() => {
                                                        markNotificationsAsRead();
                                                    }}
                                                >
                                                    Mark All As Read
                                                </Typography>
                                            )}
                                            <IconButton size="small" onClick={handleToggle}>
                                                <CloseOutlined />
                                            </IconButton>
                                        </Box>
                                    }
                                    sx={{ width: { md: '30rem', xs: '21rem' }, marginLeft: { md: '0px', xs: '-4rem' } }}
                                >
                                    {show && notifications && notifications.length > 0 && (
                                        <NotificationList notifications={notifications} setBellActive={setBellActive} />
                                    )}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <div>
                {/* <button onClick={}>Open Modal</button> */}
                <Modal isOpen={isOpen} closeModal={closeModal}>
                    {content}
                </Modal>
            </div>
            {/* {openCall ? <NotifyCall /> : <></>} */}
        </Box>
    );
};

export default Notification;
