import React from 'react';
import { Box, Typography } from '@mui/material';
import noContent from '../assets/static_images/noContent.svg'; // Assuming this is the correct path

export default function NotAvailableContent() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <img src={noContent} style={{ width: '24px', height: '24px' }} alt="no-content" />
            <Typography variant="h5" sx={{ marginTop: '1rem', opacity: '70%', fontSize: '16px' }}>
                There is no content posted in the page.
            </Typography>
        </Box>
    );
}
