/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import Love from '../../assets/static_images/liveblank.svg';
import CreateComment from './CreateComment';
import Replies from './Replies';
import axios from 'lib/axios';
import ThreeDot from '../../assets/static_images/threedot.svg';
import Card from '@mui/material/Card';
import EditComment from './EditComment';
import Modal from '@mui/material/Modal';
import DeleteComment from 'components/modals/DeleteComment';
import { Avatar, Box } from '@mui/material';

const Comment = ({ comment, post, model, setComments, setCommentCount }) => {
    const [likes, setLikes] = useState(20);
    const [openReply, setOpenreply] = useState(false);
    const [replies, setReplies] = useState([]);
    const [url, setUrl] = useState(model == 'post' ? '/comments/child-comments' : `/${model}-comments/child-comments`);
    const [openOption, setOpenOption] = useState(false);
    const [body, setBody] = useState(comment.comment_body);
    const [editorOpen, setEditorOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    useEffect(() => {
        axios.get(`${url}/${comment.comment_id}`).then(({ data }) => {
            if (data.success) {
                setReplies(data.comments);
            }
        });
    }, []);
    const timeDiffer = (now, date) => {
        var diffMs = now - date; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        return diffDays > 0
            ? `${diffDays} day${diffDays > 1 ? 's' : ''}, ${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : diffHrs > 0
            ? `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
    };
    return (
        <Box sx={{ marginY: '.5rem' }}>
            <Box display="flex" alignItems="center" gap="0.5rem" marginBottom="1rem">
                <Avatar src={avatar1} alt="avatar" className="" style={{ borderRadius: '50%' }} />
                <Box flex="1">
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: '13px',
                            fontWeight: '400',
                            lineHeight: '1rem',
                            letterSpacing: '0em',
                            textAlign: 'left'
                        }}
                    >{`${comment.user.first_name} ${comment.user.last_name}`}</Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            opacity: '0.5',
                            fontSize: '13px',
                            fontWeight: '400',
                            lineHeight: '1rem',
                            letterSpacing: '0em',
                            textAlign: 'left'
                        }}
                    >{`${timeDiffer(new Date(), new Date(comment.createdAt))}`}</Typography>
                </Box>
                <Box sx={{ position: 'relative' }}>
                    {JSON.parse(window.localStorage.getItem('authUser'))?.user_id == post.user_id ||
                    JSON.parse(window.localStorage.getItem('authUser'))?.user_id == comment.user.user_id ? (
                        <Box
                            className="cursor-pointer"
                            display="flex"
                            justifyContent="end"
                            alignItems="center"
                            onClick={() => {
                                setOpenOption(!openOption);
                            }}
                        >
                            <img src={ThreeDot} alt="dot" style={{ marginLeft: 'auto' }} />
                        </Box>
                    ) : (
                        <></>
                    )}
                    {openOption ? (
                        <Grid
                            item
                            sx={{
                                zIndex: '1024',
                                position: 'absolute',
                                top: '1.5rem',
                                right: '0rem',
                                width: '6rem',
                                height: '10rem'
                            }}
                        >
                            <Card sx={{ borderRadius: '10px', width: '100%' }}>
                                <Grid container display="flex" direction="column" aligItems="center" justifyContent="center">
                                    {JSON.parse(window.localStorage.getItem('authUser'))?.user_id == comment.user.user_id ? (
                                        <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            aligItems="center"
                                            sx={{ borderBottom: '1px solid #000000' }}
                                            onClick={() => {
                                                setEditorOpen(true);
                                                setOpenOption(false);
                                            }}
                                            className="cursor-pointer"
                                        >
                                            <Typography>Edit</Typography>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}

                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent="center"
                                        aligItems="center"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenDeleteModal(true);
                                            setOpenOption(false);
                                        }}
                                    >
                                        <Typography>Delete</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            {editorOpen ? (
                <EditComment
                    body={body}
                    commentId={comment.comment_id}
                    placeholder={'Write Reply ..'}
                    model={model}
                    setEditorOpen={setEditorOpen}
                    setBody={setBody}
                />
            ) : (
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '1rem',
                        letterSpacing: '0em',
                        textAlign: 'left'
                    }}
                >
                    {body}
                </Typography>
            )}
            {openDeleteModal ? (
                <Modal
                    keepMounted
                    open={openDeleteModal}
                    onClose={setOpenDeleteModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <DeleteComment
                        commentId={comment.comment_id}
                        model={model}
                        setOpenDeleteModal={setOpenDeleteModal}
                        setComments={setComments}
                        setCommentCount={setCommentCount}
                    />
                </Modal>
            ) : (
                <></>
            )}
            <Box>
                <Box display="flex" direction="row" alignItems="center" gap="1.5rem" marginTop="1rem">
                    <Box display="flex" alignItems="center" gap="0.25rem">
                        <img src={Love} alt="love" style={{ height: '13px' }} />
                        <Typography
                            variant="subtitle1"
                            sx={{ fontSize: '13px', fontWeight: '400', lineHeight: '1rem', letterSpacing: '0em' }}
                        >{`${likes} likes`}</Typography>
                    </Box>
                    <Box
                        className="cursor-pointer"
                        onClick={() => {
                            setOpenreply(!openReply);
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ fontSize: '13px', fontWeight: '400', lineHeight: '1rem', letterSpacing: '0em' }}
                        >
                            Reply
                        </Typography>
                    </Box>
                </Box>
                {openReply ? (
                    <Box marginTop="1rem">
                        <CreateComment
                            setComments={setReplies}
                            comments={replies}
                            post={post}
                            parentId={comment.comment_id}
                            placeholder={'Write Reply ..'}
                            model={model}
                        />
                    </Box>
                ) : (
                    <></>
                )}
                {replies.length > 0 ? (
                    <Box paddingLeft="1rem" marginTop="1rem" style={{ marginLeft: 'auto', borderLeft: '1px solid #30C6D940' }}>
                        <Replies replies={replies} post={post} model={model} setComments={setReplies} />
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default Comment;
