import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import warning from '../../assets/static_images/warning.svg';
export default function ModuleAccessWarning() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
            <Card>
                <CardContent display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <img src={warning} style={{ width: '50px' }} alt="no-content" />
                    </Box>
                    <Typography variant="h6" mt={2} align="center">
                        No Permission
                    </Typography>
                    <Typography variant="body1" mt={1} sx={{ opacity: '70%' }} align="center">
                        You don't have permission to access this module. Please contact your administrator.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}
