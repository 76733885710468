import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import ActivitySummary from '../ActivitySummary';
import Posts from 'pages/leaders-voice/Posts';
import axios from 'lib/axios';

const NewsFeed = () => {
    const [userData, setUserDate] = useState();
    const [posts, setPosts] = useState([]);
    const [offest, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sahredMedia, setSharedMedia] = useState();
    const [selected, setSelected] = useState();
    const [oepnViewer, setOpenViewer] = useState(false);
    useEffect(() => {
        fetchPosts();
    }, []);
    async function fetchPosts() {
        await axios
            .post(`/social-posts/myvoice`, {
                user_id: JSON.parse(window.localStorage.getItem('authUser'))?.user_id
            })
            .then(({ data }) => {
                if (data.success) {
                    setPosts(data.posts);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <Grid sm={12} container>
            <Grid sm={12} item>
                <Grid sm={12} container sx={{ paddingTop: '1.5rem', paddingX: '1.5rem' }}>
                    <Grid item sm={12}>
                        <Typography variant="h6" sx={{ fontWeight: '700' }}>
                            Recent Activities
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sm={12} sx={{ padding: { md: '1.5rem', xs: '0rem' } }}>
                    {posts.length > 0 && <Posts posts={posts} fetchPosts={fetchPosts} setPosts={setPosts} />}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NewsFeed;
