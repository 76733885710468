import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/material';
import AnalyticsChart from 'components/profile/AnalyticsChart';
import EngagementTable from 'components/profile/EngagementTable';
import ActivitySummary from 'components/profile/ActivitySummary';
import DateFilter from 'components/modals/DateFilter';

const SelfAnalytics = ({ member_id }) => {
    const [openViewer, setOpenViewer] = useState(false);
    const [completedTask, setCompletedTask] = useState(0);
    const [onProgressTask, setOnProgressTask] = useState(0);
    const [todoTask, setTodoTask] = useState(0);
    const [onGoingProject, setOnGoingProject] = useState(0);
    const [completedProject, setCompletedProject] = useState(0);
    const [attendedEvent, setAttendedEvent] = useState(0);
    const [notAttendedEvent, setNotAttendedEvent] = useState(0);
    const [attendedMeeting, setAttendedMeeting] = useState(0);
    const [notAttendedMeeting, setNotAttendedMeeting] = useState(0);
    const [successStory, setSuccessStory] = useState(0);
    const [skills, setSkills] = useState(0);
    const [voice, setVoice] = useState(0);
    const [policyViewed, setPolicyViewed] = useState(0);
    const [policyLikes, setPolicyLikes] = useState(0);
    const [policyComment, setPolicyComment] = useState(0);
    const [series, setSeries] = useState();
    const [engagements, setEngagements] = useState([]);
    const [monthRange, setMonthRange] = useState(0);
    const [body, setBody] = useState({});

    useEffect(() => {
        resetAll();
        if (member_id) {
            setBody({ member_id });
        }
        if (monthRange !== 0) {
            setBody(() => (member_id ? { member_id, month: monthRange } : { month: monthRange }));
        }

        fetchTaskProjectSummary();
        fetchEventSummary();
        fetchMeetingSummary();
        fetchStoriesSummary();
        fetchSkillsSummary();
        fetchPolicySummary();
        fetchEngagementSummary();
    }, [monthRange, member_id]);

    const resetAll = () => {
        setCompletedTask(0);
        setOnProgressTask(0);
        setTodoTask(0);
        setOnGoingProject(0);
        setCompletedProject(0);
        setAttendedEvent(0);
        setNotAttendedEvent(0);
        setAttendedMeeting(0);
        setNotAttendedMeeting(0);
        setSuccessStory(0);
        setSkills(0);
        setVoice(0);
        setPolicyViewed(0);
        setPolicyLikes(0);
        setPolicyComment(0);
        setSeries(undefined);
        setEngagements([]);
    };

    const fetchTaskProjectSummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/project-task', body);

            data.summary?.tasks.forEach((task) => {
                if (task.task_status_id === 1) {
                    setTodoTask((prev) => prev + parseInt(task.count));
                } else if (task.task_status_id === 2) {
                    setOnProgressTask((prev) => prev + parseInt(task.count));
                } else if (task.task_status_id === 3) {
                    setCompletedTask((prev) => prev + parseInt(task.count));
                }
            });

            data.summary?.projects?.forEach((project) => {
                if (project.project_status === 'pending') {
                    setOnGoingProject((prev) => prev + parseInt(project.count));
                } else if (project.project_status === 'completed') {
                    setCompletedProject((prev) => prev + parseInt(project.count));
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchEventSummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/events', body);

            data.summary?.forEach((item) => {
                if (item.rsvp === 'yes') {
                    setAttendedEvent((prev) => prev + parseInt(item.count));
                } else if (item.rsvp === 'no') {
                    setNotAttendedEvent((prev) => prev + parseInt(item.count));
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchMeetingSummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/meeting', body);
            if (data.success) {
                setAttendedMeeting(parseInt(data.summary.attended));
                setNotAttendedMeeting(parseInt(data.summary.upcoming));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchStoriesSummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/stories', body);
            if (data.success) {
                setSuccessStory(parseInt(data.summary.success[0].count));
                setVoice(parseInt(data.summary.voice[0].count));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchSkillsSummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/skills', body);
            if (data.success) {
                setSkills(parseInt(data.summary[0].count));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchPolicySummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/policy', body);
            if (data.success) {
                setPolicyViewed(parseInt(data.summary.commentscount[0].count));
                setPolicyLikes(parseInt(data.summary.likescount[0].count));
                setPolicyComment(parseInt(data.summary.viewd[0].count));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchEngagementSummary = async () => {
        try {
            const { data } = await axios.post('/activities/personal-summary/engagement', body);
            if (data.success && data.summary) {
                const array = [
                    { id: 'tasks', name: 'Tasks', value: parseInt(data.summary.tasks[0].count) },
                    { id: 'project', name: 'Project', value: parseInt(data.summary.project[0].count) },
                    { id: 'success_stories', name: 'Success Stories', value: parseInt(data.summary.success_stories[0].count) },
                    { id: 'event', name: 'Event', value: parseInt(data.summary.event[0].count) },
                    { id: 'meeting', name: 'Meeting', value: parseInt(data.summary.meeting[0].count) },
                    { id: 'knowledge', name: 'Knowledge & Skills', value: parseInt(data.summary.knowledge[0].count) },
                    { id: 'policy', name: 'Policy & Procedure', value: parseInt(data.summary.policy[0].count) },
                    { id: 'voice', name: 'Voice', value: parseInt(data.summary.voice[0].count) }
                ];

                setEngagements(array);
                setSeries([
                    {
                        name: 'Engagements',
                        data: array.map((item) => item.value)
                    }
                ]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const cardBgColor = member_id && member_id !== 0 ? '#30C6D9' : '#1E7C88';

    return (
        <Card sx={{ maxHeight: 'calc(100vh - 7.5rem)', overflowY: 'auto' }}>
            <Box sx={{ padding: '2rem', width: '100%' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginY: '1rem' }}>
                    <Box>
                        <Typography variant="h4" color="primary" sx={{ fontWeight: '600' }}>
                            Analytics
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl>
                            <Select value={monthRange} onChange={(e) => setMonthRange(e.target.value)}>
                                <MenuItem value={0}>
                                    <Typography>
                                        {`${new Date().toString().substring(3, 7)},${new Date().toString().substring(10, 16)}`}
                                    </Typography>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <Typography>Last Month</Typography>
                                </MenuItem>
                                <MenuItem value={3}>
                                    <Typography>Last 3 Month</Typography>
                                </MenuItem>
                                <MenuItem value={6}>
                                    <Typography>Last 6 Month</Typography>
                                </MenuItem>
                                <MenuItem value={12}>
                                    <Typography>Last 1 Year</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Grid container columns={{ xs: 12 }} spacing={1}>
                    <Grid container xs={12} sm={6}>
                        {/* Tasks */}
                        <Grid item xs={12} sm={6} sx={{ width: '100%' }}>
                            <Box
                                display="flex"
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1.5rem',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box sx={{ paddingY: '1rem' }}>
                                    <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                        Tasks
                                    </Typography>
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                >
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                            COMPLETED
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="white" variant="h4" sx={{ fontWeight: '500' }}>
                                            {completedTask}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                >
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                            On Going
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                            {onProgressTask}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ paddingY: '.5rem' }}>
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                            To Do
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                            {todoTask}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Projects */}
                        <Grid item xs={12} sm={6}>
                            <Box
                                display="flex"
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1.5rem',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box sx={{ paddingY: '1rem' }}>
                                    <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                        Projects
                                    </Typography>
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                >
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                            TOTAL
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="white" variant="h4" sx={{ fontWeight: '500' }}>
                                            {completedProject + onGoingProject}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                >
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                            COMPLETED
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                            {completedProject}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ paddingY: '.5rem' }}>
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                            On Going
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                            {onGoingProject}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} sm={6}>
                        {/* Policy & Procedure */}
                        <Grid item xs={12} sm={12}>
                            <Box
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1rem',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box sx={{ paddingY: '1rem' }}>
                                    <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                        Policy & Procedure
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={{ md: 5, xs: 2 }} sx={{ width: '100%', overflowX: 'scroll' }}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography variant="h6" color="white">
                                            {policyViewed}
                                        </Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Viewed
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography variant="h6" color="white">
                                            {policyLikes}
                                        </Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Liked
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '600' }}>
                                            {policyComment}
                                        </Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Commented
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Events */}
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1rem',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box sx={{ paddingY: '1rem' }}>
                                    <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                        Events
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={5}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography color="white" variant="h6" sx={{ fontWeight: '600' }}>
                                            {attendedEvent}
                                        </Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Attended
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography sx={{ opacity: '80%', color: 'red', fontWeight: '600' }}>{notAttendedEvent}</Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Absent
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Meetings */}
                        <Grid xs={12} sm={6}>
                            <Box
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1rem',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box sx={{ paddingY: '1rem' }}>
                                    <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                        Meetings
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={5}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography color="white" variant="h6">
                                            {attendedMeeting}
                                        </Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Attended
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography variant="h6" sx={{ fontWeight: '600', color: '#FF9820', opacity: '80%' }}>
                                            {notAttendedMeeting}
                                        </Typography>
                                        <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                            Upcoming
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} sm={12}>
                        {/* Success Stories */}
                        <Grid xs={12} sm={4}>
                            <Box
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1rem',
                                    flexDirection: { md: 'row', xs: 'column' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                gap={2}
                            >
                                <Box sx={{ paddingY: { md: '1rem' } }}>
                                    <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                        {successStory}
                                    </Typography>
                                </Box>
                                <Box sx={{ paddingY: { md: '1rem' } }}>
                                    <Typography
                                        color="white"
                                        sx={{ opacity: '80%', color: '#CBFDFD', textAlign: { md: 'start', xs: 'center' } }}
                                    >
                                        Submitted
                                    </Typography>
                                    <Typography
                                        color="white"
                                        variant="h5"
                                        sx={{ fontWeight: '600', textAlign: { md: 'start', xs: 'center' } }}
                                    >
                                        SUCCESS STORIES
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Knowledge & Skills */}
                        <Grid xs={12} sm={4}>
                            <Box
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1rem',
                                    flexDirection: { md: 'row', xs: 'column' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                gap={2}
                            >
                                <Box sx={{ paddingY: { md: '1rem' } }}>
                                    <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                        {skills}
                                    </Typography>
                                </Box>
                                <Box sx={{ paddingY: { md: '1rem' }, textAlign: { md: 'start', xs: 'center' } }}>
                                    <Typography
                                        color="white"
                                        sx={{ opacity: '80%', color: '#CBFDFD', textAlign: { md: 'start', xs: 'center' } }}
                                    >
                                        Added
                                    </Typography>
                                    <Typography
                                        color="white"
                                        variant="h5"
                                        sx={{ fontWeight: '600', textAlign: { md: 'start', xs: 'center' } }}
                                    >
                                        KNOWLEDGE & SKILLS
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Voice */}
                        <Grid xs={12} sm={4}>
                            <Box
                                sx={{
                                    background: cardBgColor,
                                    borderRadius: '5px',
                                    padding: '1rem',
                                    flexDirection: { md: 'row', xs: 'column' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                gap={2}
                            >
                                <Box sx={{ paddingY: { md: '1rem' } }}>
                                    <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                        {voice}
                                    </Typography>
                                </Box>
                                <Box sx={{ paddingY: { md: '1rem' } }}>
                                    <Typography
                                        color="white"
                                        sx={{ opacity: '80%', color: '#CBFDFD', textAlign: { md: 'start', xs: 'center' } }}
                                    >
                                        Submits
                                    </Typography>
                                    <Typography
                                        color="white"
                                        variant="h5"
                                        sx={{ fontWeight: '600', textAlign: { md: 'start', xs: 'center' } }}
                                    >
                                        VOICE
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <ActivitySummary />

                <Grid container sm={12} sx={{ marginY: '1rem' }}>
                    <Grid item sm={12} sx={{ marginY: '1rem' }}>
                        <Typography variant="h4" color="primary">
                            Engagement Analytics
                        </Typography>
                    </Grid>
                    {series && (
                        <Grid item sm={12} sx={{ marginY: '1rem' }}>
                            <AnalyticsChart series={series} color={cardBgColor} />
                        </Grid>
                    )}
                </Grid>

                <Grid container sm={12} sx={{ marginY: '1rem' }}>
                    {engagements?.length > 0 && <EngagementTable engagements={engagements} color={cardBgColor} />}
                </Grid>
            </Box>

            <Modal
                keepMounted
                open={openViewer}
                onClose={() => setOpenViewer(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Grid container display="flex" justifyContent="center" alignItems="end" sx={{ width: '100vw' }}>
                    <DateFilter setOpenViewer={setOpenViewer} setMonthRange={setMonthRange} />
                </Grid>
            </Modal>
        </Card>
    );
};

export default SelfAnalytics;
