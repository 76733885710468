/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Link, Grid, Box, InputLabel, OutlinedInput, Snackbar, useTheme, useMediaQuery, Typography } from '@mui/material';
import { htmlContent, urlify } from 'Helpers';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Cross from '../../assets/static_images/cross.svg';
import Button from '@mui/material/Button';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import FileIcon from '../../assets/static_images/fileiconactive.svg';
import FileDownload from 'js-file-download';
import axios from 'lib/axios';
const PostBody = ({ post, type, setOpenGallery, user }) => {
    const [showMore, setShowMore] = useState(false);

    const preRef = useRef(null);
    useEffect(() => {
        if (preRef.current) {
            const button = preRef.current.querySelector('#showMoreButton');
            if (button) {
                button.addEventListener('click', () => {
                    setShowMore(true);
                });
            }
        }
    }, []);
    async function download_file(path) {
        try {
            const response = await axios({
                url: `${path}`,
                method: 'GET',
                responseType: 'blob'
            });
            const filename = path.split('/').pop();
            FileDownload(response.data, filename);
        } catch (err) {
            console.log('Error downloading file:', err);
        }
    }
    return (
        <Box>
            <Box className="content-body" marginY="1rem" sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        textWrap: 'wrap',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {urlify(post.plain_text_body).length > 150
                        ? showMore
                            ? htmlContent(post.plain_text_body)
                            : htmlContent(post.plain_text_body, true, 200, preRef)
                        : htmlContent(post.plain_text_body)}
                </Box>
                {/* </Grid> */}

                {type == 'non-view' && post?.image && post.image?.length > 0 && post.image?.filter(Boolean).length > 0 && (
                    <Box
                        mt={1}
                        className="cursor-pointer"
                        onClick={() => {
                            setOpenGallery(true);
                        }}
                        sx={{ position: 'relative' }}
                    >
                        <Grid
                            container
                            display="grid"
                            gap="1rem"
                            sx={{
                                gridTemplateColumns:
                                    post.image.filter(Boolean).length === 1 ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'
                            }}
                        >
                            {post.image?.filter(Boolean).map((item, index) => {
                                let col = 12;

                                if (post.image?.filter(Boolean).length > 3) {
                                    col = index == 0 ? 12 : 4;
                                } else if (post.image?.filter(Boolean).length == 2) {
                                    col = 6;
                                }
                                if (post.image?.filter(Boolean).length == 3) {
                                    if (index == 0) {
                                        col = 12;
                                    } else {
                                        col = 6;
                                    }
                                }

                                if (index < 3) {
                                    return (
                                        <Box
                                            key={`${post.post_id}lil${index}`}
                                            style={{
                                                gridColumn: `span ${post.image?.filter(Boolean).length === 3 && index == 2 ? 2 : 1}`
                                            }}
                                        >
                                            {post.image.filter(Boolean).length === 1 ? (
                                                <img
                                                    src={`${item}`}
                                                    alt="pic"
                                                    className="img-fit"
                                                    style={{
                                                        borderRadius: '10px',
                                                        height: '336px',
                                                        width: '100%',
                                                        objectFit: 'cover',
                                                        background: 'lightgray'
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={`${item}`}
                                                    alt="pic"
                                                    className="img-fit"
                                                    style={{
                                                        borderRadius: '10px',
                                                        height: '336px',
                                                        width: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    );
                                } else if (index == 3) {
                                    return (
                                        <Box key={`${post.post_id}lil${index}`}>
                                            <Box sx={{ position: 'relative' }}>
                                                <Box>
                                                    <img
                                                        src={`${item}`}
                                                        alt="pic"
                                                        className="img-fit"
                                                        style={{ borderRadius: '10px', height: '336px', width: '100%', objectFit: 'cover' }}
                                                    />
                                                </Box>
                                                {post.image?.filter(Boolean).length - 4 > 0 && (
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: '10px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                        className="image-cover"
                                                    >
                                                        <div>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    color: '#FFFFFF',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                +{post.image?.filter(Boolean).length - 4}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                }
                            })}
                        </Grid>
                    </Box>
                )}

                {type == 'non-view' &&
                    post.audio &&
                    post.audio.length > 0 &&
                    post.audio.filter(Boolean).map((audio) => {
                        return (
                            <Box display="flex" justifyContent="center" alignItems="center" key={audio}>
                                <audio controls>
                                    <source src={`${audio}`} type="audio/mpeg" />
                                    <source src={`${audio}`} type="audio/wav" />
                                    <source src={`${audio}`} type="audio/ogg" />
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>
                        );
                    })}
                {type == 'non-view' && post?.video && post.video.length > 0 && (
                    <Box
                        className="cursor-pointer"
                        onClick={() => {
                            post?.image?.length > 1 ? setOpenGallery(true) : setOpenGallery(false);
                        }}
                        sx={{ position: 'relative' }}
                    >
                        <Grid
                            container
                            display="grid"
                            gap="1rem"
                            style={{
                                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
                            }}
                        >
                            {post?.video.map((item, index) => {
                                let col = 12;

                                if (post.video.length > 3) {
                                    col = index == 0 ? 12 : 4;
                                } else if (post.video.length == 2) {
                                    col = 6;
                                }
                                if (post.video.length == 3) {
                                    if (index == 0) {
                                        col = 12;
                                    } else {
                                        col = 6;
                                    }
                                }

                                if (index < 3) {
                                    return (
                                        <Box
                                            key={`${post.post_id}lil${index}`}
                                            style={{
                                                gridColumn: `span ${post.video.length == 1 ? 2 : 1}`
                                            }}
                                        >
                                            <video
                                                className="img-fit"
                                                controls
                                                src={`${item}`}
                                                style={{ borderRadius: '10px', height: '100%', width: '100%' }}
                                            />
                                        </Box>
                                    );
                                } else if (index == 3) {
                                    return (
                                        <Box key={`${post.post_id}lil${index}`}>
                                            <Box sx={{ position: 'relative' }}>
                                                <Box>
                                                    <img
                                                        src={`${item}`}
                                                        alt="pic"
                                                        className="img-fit"
                                                        style={{ borderRadius: '10px', height: '100%', width: '100%' }}
                                                    />
                                                </Box>
                                                {post.video.length - 4 > 0 && (
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: '10px'
                                                        }}
                                                        className="video-cover"
                                                    >
                                                        <div>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    color: '#FFFFFF',
                                                                    textAlign: 'center',
                                                                    marginTop: '1rem'
                                                                }}
                                                            >
                                                                +{post.video.length - 4}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                }
                            })}
                        </Grid>
                    </Box>
                )}

                {type == 'non-view' &&
                    post.files &&
                    post.files.length > 0 &&
                    post.files.map((video) => {
                        return (
                            <Grid container sm={12} spacing={1} px={{ paddingY: '1rem', marginBottom: '.5rem' }}>
                                <Grid item xl={0.5} md={1} display="flex" justifyContent="start" alignItems="center">
                                    <img src={FileIcon} width={26} height={26} alt="pdficon"></img>
                                </Grid>
                                <Grid
                                    item
                                    sm={10}
                                    display="flex"
                                    justifyContent="start"
                                    alignItems="center"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        download_file(video.url);
                                    }}
                                >
                                    <Typography sx={{ fontSize: '16px' }}>{video.name}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default PostBody;
