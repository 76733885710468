/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';
import axios from 'lib/axios';
import Comment from '../../assets/static_images/comment.svg';
import Reacts from '../../components/Reacts';
import CreateComment from 'components/comment/CreateComment';
import Modal from '@mui/material/Modal';
import Gallery from 'components/Gallery';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import Blank from '../../assets/static_images/blank.svg';
import NewsModal from 'components/modals/NewsModal';
import DeleteModal from 'components/modals/DeleteModal';
import { Box } from '@mui/material';
import ViewReactModal from 'components/modals/ViewReactModal';
import ParentComment from 'components/comment/ParentComment';
import NewsHeader from './NewsHeader';
import NewsBody from './NewsBody';
import HasPermission from 'utils/HasPermission';
const News = ({ news, reacts, setNewsList, type = 'non-view', single = false, borderNone, lastPostRef, index, newsList }) => {
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [commentOpen, setCommentOpen] = useState(false);
    const [reactCount, setReactCount] = useState(news.news_reacts?.length);
    const [comments, setComments] = useState(news.news_comments ?? []);
    const [openEditor, setOpenEditor] = useState(false);
    const [fetchedCommentsCount, setFetchedCommentsCount] = useState(1);

    const [viewAllComments, setViewAllComments] = useState(true);
    const [openGallery, setOpenGallery] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openSnack, setShowSnack] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    const [openModal, setOpenModal] = useState(false);
    const [commentCount, setCommentCount] = useState(() => news.newsCommentCount);
    const [hideComments, setHideComments] = useState(true);
    const [openViewReactModal, setOpenViewReactModal] = useState(false);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [childOffset, setChildOffset] = useState(0);
    const [childLimit, setChildLimit] = useState(5);
    const [orderBy, setOrderBy] = useState('created');
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    function handleSnackClose() {
        setShowSnack(false);
    }
    const fetchComments = async () => {
        const formData = new FormData();

        formData.append('offset', parseInt(offset));
        formData.append('limit', parseInt(limit));
        formData.append('child_offset', parseInt(childOffset));
        formData.append('child_limit', parseInt(childLimit));
        formData.append('order_by', 'count');
        await axios.post(`/news-comments/${news.news_id}`, formData).then(({ data }) => {
            if (data.success) {
                // console.log(data);
                setComments([...comments, ...data.comments]);
                setOffset((ofst) => parseInt(ofst) + 3);
                setFetchedCommentsCount((prev) => prev + 3);
            }
        });
    };
    const [id, setId] = useState(`news-${news.news_id}`);
    const adjustReact = async () => {
        await axios
            .delete('/news-reacts/destroy', { data: { news_id: news.news_id } })
            .then(({ data }) => {
                if (data.success) {
                    setReacted(false);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        axios.get(`news/reacted/${news.news_id}`).then(({ data }) => {
            if (data.success) {
                setReacted(data.reacted);
                if (data.react !== null) {
                    setReaction(data.react.react.react_name);
                    if (data.react.react.react_id == 1) {
                        setReactionImg(Like);
                    } else if (data.react.react.react_id == 2) {
                        setReactionImg(Love);
                    } else if (data.react.react.react_id == 3) {
                        setReactionImg(Haha);
                    } else if (data.react.react.react_id == 4) {
                        setReactionImg(Wow);
                    } else if (data.react.react.react_id == 5) {
                        setReactionImg(Sad);
                    } else if (data.react.react.react_id == 6) {
                        setReactionImg(Angry);
                    }
                }
            }
        });

        // fetchComments();
    }, []);
    useEffect(() => {
        HasPermission(setCanEdit, 'News', 'edit', 'news');
        HasPermission(setCanDelete, 'News', 'delete', 'news');
        fetchComments();
    }, []);

    return (
        <Box
            sx={{ minHeight: '100%', minWidth: '100%', mb: '10px', mt: '10px' }}
            ref={newsList.length >= 5 && index === newsList?.length - 1 ? lastPostRef : null}
        >
            <Box
                sx={{
                    borderRadius: '10px',
                    padding: '1rem',
                    width: '100%',
                    backgroundColor: '#fff',
                    border: `${!borderNone && '1px solid #1E7C8840'}`
                }}
            >
                <Box>
                    {/* card header  */}
                    <NewsHeader
                        news={news}
                        openOption={openOption}
                        setOpenOption={setOpenOption}
                        setOpenEditor={setOpenEditor}
                        setOpenDeleteModal={setOpenDeleteModal}
                        canEdit={canEdit}
                        canDelete={canDelete}
                    />
                    {/* card  body  */}
                    <NewsBody news={news} type={type} setOpenGallery={setOpenGallery} />
                    {/* reaction section  */}
                    <Box display="flex" alignItems="center" marginBottom="1rem">
                        <Box display="flex" alignItems="center" justifyContent="space-between" gap="1rem" sx={{ width: '100%' }}>
                            <Box display="flex" alignItems="center" gap="1rem">
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                                        <Box
                                            className="cursor-pointer"
                                            onClick={() => {
                                                adjustReact();
                                                setReactOpen(!reactOpen);
                                            }}
                                        >
                                            <Box>
                                                {reacted ? (
                                                    <Box display="flex" alignItems="center" gap="0.5rem">
                                                        <Box display="flex" alignItems="center" justifyContent="center">
                                                            <img
                                                                src={reactionImg}
                                                                alt="icn"
                                                                style={{
                                                                    height: '1rem',
                                                                    width: '1rem'
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>{reaction}</Typography>
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box display="flex" alignItems="center" gap="0.5rem">
                                                        <Box display="flex" alignItems="center" justifyContent="center">
                                                            <img
                                                                src={Blank}
                                                                alt="icn"
                                                                style={{
                                                                    height: '1rem',
                                                                    width: '1rem'
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>Like</Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                        {reactOpen ? (
                                            <Grid
                                                item
                                                sx={{
                                                    zIndex: '1024',
                                                    position: 'absolute',
                                                    top: '-4rem',
                                                    left: '1.5rem',
                                                    width: '23rem'
                                                }}
                                            >
                                                <Reacts
                                                    modelValue={news}
                                                    reacts={reacts}
                                                    setReactOpen={setReactOpen}
                                                    setReacted={setReacted}
                                                    setReactCount={setReactCount}
                                                    setReactionImg={setReactionImg}
                                                    setReaction={setReaction}
                                                    model={'news'}
                                                />
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setCommentOpen(!commentOpen);
                                    }}
                                    display="flex"
                                    alignItems="center"
                                    gap="0.5rem"
                                >
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <img
                                            src={Comment}
                                            alt="icn"
                                            style={{
                                                height: '1rem',
                                                width: '1rem'
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>Comment</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                className="cursor-pointer"
                                onClick={() => {
                                    setHideComments((prev) => {
                                        if (!prev) {
                                            fetchComments();
                                        }
                                        return !prev;
                                    });
                                }}
                            >
                                <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>{`${commentCount} Comments`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {/* stats section  */}
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ gap: '0.25rem', cursor: 'pointer' }}
                        onClick={() => {
                            setOpenViewReactModal(true);
                        }}
                    >
                        {reacted ? <Typography variant="subtitle1">You</Typography> : <></>}
                        {reactCount > 0 && (
                            <Typography variant="h6">
                                {reacted ? 'and' : ''} {reactCount} {reactCount > 1 ? "other's" : 'other'}
                            </Typography>
                        )}
                        {(reactCount > 0 || reacted) && <Typography variant="subtitle1">reacted on this news</Typography>}
                    </Box>
                    {/* comments section  */}
                    {commentOpen ? (
                        <Grid item sm={12}>
                            <CreateComment
                                setComments={setComments}
                                comments={comments}
                                post={news}
                                model={'news'}
                                setCommentCount={setCommentCount}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    {comments?.length > 0 && !hideComments ? (
                        <Grid item xs={12}>
                            <Grid container display="flex" direction="column" sm={12}>
                                <Box>
                                    <ParentComment
                                        comments={comments}
                                        setComments={setComments}
                                        setCommentCount={setCommentCount}
                                        setOrderBy={setOrderBy}
                                        orderBy={orderBy}
                                        type="news"
                                    />
                                </Box>
                                {/* <CommentList
                                        comments={comments}
                                        post={news}
                                        model={`news`}
                                        setComments={setComments}
                                        setCommentCount={setCommentCount}
                                    /> */}
                            </Grid>
                            {fetchedCommentsCount <= parseInt(news.NewsCommentCount) && parseInt(news.NewsCommentCount) > 2 ? (
                                <Grid container display="flex" justifyContent="start" sm={12}>
                                    <Grid
                                        item
                                        className="cursor-pointer"
                                        onClick={() => {
                                            fetchComments();
                                            setViewAllComments(false);
                                        }}
                                    >
                                        <Typography variant="subtitle1">{`See More`}</Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {fetchedCommentsCount > parseInt(news.NewsCommentCount) ? (
                                <Grid container display="flex" justifyContent="start" sm={12}>
                                    <Grid
                                        item
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setHideComments(true);
                                        }}
                                    >
                                        <Typography variant="subtitle1">{`Hide comments`}</Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            {openEditor ? (
                <Modal
                    keepMounted
                    open={openEditor}
                    onClose={setOpenEditor}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <NewsModal
                        setOpenEditor={setOpenEditor}
                        news={news}
                        content={news?.plain_text_body}
                        user={news?.user}
                        setNewsList={setNewsList}
                        setOpenOption={setOpenOption}
                        showSnack={(isError, message) => {
                            setSnackOptions({ isError, message });
                            setShowSnack(true);
                        }}
                    />
                </Modal>
            ) : (
                <></>
            )}

            {news.image ? (
                <Modal
                    keepMounted
                    open={openGallery}
                    onClose={setOpenGallery}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
                        <Grid item sm={10} sx={{ position: 'relative', top: '10vh' }}>
                            <Gallery
                                images={news.image.filter((element) => element !== null)}
                                videos={news.video.filter((element) => element !== null)}
                                setOpenGallery={setOpenGallery}
                            />
                        </Grid>
                        {/* <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                position: 'absolute',
                                left: '5rem',
                                top: '8rem',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '50%',
                                padding: '.7rem'
                            }}
                            className="cursor-pointer"
                            onClick={() => {
                                setOpenGallery(!openGallery);
                            }}
                        >
                            <img src={Cross} alt="img" />
                        </Grid> */}
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
            {openDeleteModal ? (
                <Modal
                    keepMounted
                    open={openDeleteModal}
                    onClose={setOpenDeleteModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <DeleteModal
                        setOpenDeleteModal={setOpenDeleteModal}
                        url={`/news/delete`}
                        id={news.news_id}
                        showSnack={(isError, message) => {
                            setSnackOptions({ isError, message });
                            setShowSnack(true);
                        }}
                        setPosts={setNewsList}
                        model={`news`}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openViewReactModal && (
                <ViewReactModal
                    post={news}
                    type="news"
                    endpoint="/news-reacts/list"
                    setOpenModal={setOpenViewReactModal}
                    openModal={openViewReactModal}
                />
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default News;
