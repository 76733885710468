import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableBody,
    Switch,
    CircularProgress
} from '@mui/material';
import plus from '../../assets/static_images/plus.svg';
import { useEffect, useState } from 'react';
import ModuleAndCategoryCreateModal from 'components/modals/ModuleAndCategoryCreateModal';
import axios from 'lib/axios';

const ModulePermission = () => {
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [moduleCategoriesList, setModuleCategoriesList] = useState([]);
    const [moduleList, setModuleList] = useState([]);
    const [activeTab, setActiveTab] = useState('module');
    const [loadingPermission, setLoadingPermission] = useState(null);

    async function togglePermission(companyModuleId, companyId, isActive) {
        setLoadingPermission(companyModuleId);
        try {
            const response = await axios.post(`/company-module/update`, {
                company_module_id: companyModuleId,
                company_id: companyId,
                is_active: isActive
            });
            console.log(response);
            if (response.data.success) {
                setModuleList((prevList) =>
                    prevList.map((item) => {
                        if (item.company_module_id === companyModuleId) {
                            return {
                                ...item,
                                is_active: response.data.data.is_active
                            };
                        }
                        return item;
                    })
                );
            }
        } catch (error) {
            console.error('Error updating permission:', error);
        } finally {
            setLoadingPermission(null);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    activeTab === 'module'
                        ? `/company-module?company_id=${JSON.parse(localStorage.getItem('authUser'))?.company_id}`
                        : '/module-category'
                );
                if (res.data.success && activeTab === 'module') {
                    setModuleList(res.data.data);
                }
                if (res.data.success && activeTab === 'category') {
                    setModuleCategoriesList(res.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [activeTab]);

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    {/* 1 */}
                    <Grid
                        item
                        onClick={() => {
                            setModalType('create_category');
                            setOpenModal(true);
                        }}
                        md={3}
                    >
                        <Card
                            sx={{
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Typography color="primary" variant="h5" sx={{ fontWeight: 'bold' }}>
                                    Create Module Category
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: '#1E7C88',
                                        p: 3,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        mt: 2
                                    }}
                                >
                                    <img src={plus} alt="plus" />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* 2 */}
                    <Grid item md={3}>
                        <Card
                            sx={{
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                            onClick={() => {
                                setModalType('create_module');
                                setOpenModal(true);
                            }}
                        >
                            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Typography color="primary" variant="h5" sx={{ fontWeight: 'bold' }}>
                                    Create Module
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: '#1E7C88',
                                        p: 3,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        mt: 2
                                    }}
                                >
                                    <img src={plus} alt="plus" />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* table */}
                <Box mt={4}>
                    <Box display="flex" alignItems="center" sx={{ maxWidth: '20%', gap: '10px' }} mb={1}>
                        <Box
                            onClick={() => setActiveTab('module')}
                            sx={{
                                background: activeTab === 'module' ? '#1E7C88' : 'white',
                                color: activeTab === 'module' ? 'white' : 'black',
                                px: 3,
                                py: 1,
                                boxShadow: 1,
                                borderRadius: 1,
                                cursor: 'pointer'
                            }}
                        >
                            Module
                        </Box>
                        <Box
                            onClick={() => setActiveTab('category')}
                            sx={{
                                background: activeTab === 'category' ? '#1E7C88' : 'white',
                                color: activeTab === 'category' ? 'white' : 'black',
                                px: 3,
                                py: 1,
                                boxShadow: 1,
                                borderRadius: 1,
                                cursor: 'pointer'
                            }}
                        >
                            Module Categories
                        </Box>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">No</TableCell>
                                    <TableCell>Category Name</TableCell>
                                    {activeTab === 'module' && (
                                        <>
                                            <TableCell align="left">Module</TableCell>
                                            <TableCell align="left">Status</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activeTab === 'module' ? (
                                    <>
                                        {moduleList.map((module, index) => (
                                            <TableRow key={module.module_id}>
                                                <TableCell align="left">{index + 1}</TableCell>
                                                <TableCell>{module.module.module_category?.title}</TableCell>
                                                <TableCell>{module.module.title}</TableCell>
                                                <TableCell>
                                                    <Box>
                                                        {loadingPermission === module.permission_id ? (
                                                            <Box
                                                                sx={{ width: '60px', height: '38px' }}
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <CircularProgress size={20} />
                                                            </Box>
                                                        ) : (
                                                            <Switch
                                                                checked={module.is_active}
                                                                onClick={() => {
                                                                    togglePermission(
                                                                        module.company_module_id,
                                                                        JSON.parse(localStorage.getItem('authUser'))?.company_id,
                                                                        !module.is_active
                                                                    );
                                                                }}
                                                                disabled={loadingPermission === module.company_module_id}
                                                                color="primary"
                                                            />
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {moduleCategoriesList.map((moduleCategory, index) => (
                                            <TableRow key={moduleCategory.module_category_id}>
                                                <TableCell align="left">{index + 1}</TableCell>
                                                <TableCell>{moduleCategory.title}</TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>

            {/* modal */}
            {openModal && (
                <ModuleAndCategoryCreateModal
                    isOpen={openModal}
                    handleClose={() => setOpenModal(false)}
                    type={modalType}
                    setModuleCategoriesList={setModuleCategoriesList}
                    moduleCategoriesList={moduleCategoriesList}
                />
            )}
        </>
    );
};

export default ModulePermission;
