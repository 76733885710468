/* eslint-disable react/prop-types */
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import TickMark from '../../assets/static_images/tikcircle.svg';
import { Box } from '@mui/material';

const SuccessModal = ({ setOpenMessage, is_task = false, message = 'Task created successfully', type, alertMessage, setAlertMessage }) => {
    return (
        <Grid container sm={12} display="f lex" alignItems="center" justifyContent="center" sx={{ height: '60vh' }}>
            <Grid item sm={4} display="flex" justifyContent="center" alignItems="center">
                <Card
                    sx={{
                        paddingBottom: '0px',
                        borderRadius: '10px',
                        width: { md: '50vw', xs: '100vw' },
                        marginTop: '35%',
                        position: 'relative'
                    }}
                >
                    <Grid>
                        <Box sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                            <Badge
                                color="primary"
                                badgeContent={'X'}
                                className="cursor-pointer"
                                onClick={() => {
                                    setOpenMessage(false);
                                    if (type === 'login') {
                                        setAlertMessage('');
                                    }
                                }}
                                sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                            ></Badge>
                        </Box>

                        <Grid
                            container
                            sm={12}
                            sx={{ background: '#1E7C88', height: '35vh', borderRadius: '.5rem' }}
                            display="flex"
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <>
                                <Grid item display="flex" alignItems="center" justifyContent="center">
                                    <img src={TickMark} alt="tickmark" />
                                </Grid>
                                <Grid item mt={1} display="flex" alignItems="center" justifyContent="center" p={1}>
                                    <Typography variant={type === 'login' ? 'h5' : 'h4'} color="white" textAlign="center">
                                        {alertMessage ? alertMessage : <> Thank You !</>}
                                    </Typography>
                                </Grid>
                            </>

                            <Grid item display="flex" alignItems="center" justifyContent="center">
                                <Typography color="white" sx={{ fontSize: '20px' }}>
                                    {is_task ? `${message}` : `Your voice is submitted`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default SuccessModal;
