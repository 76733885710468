/* eslint-disable prettier/prettier */
// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items-landing';
import { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenueStat } from '../../../../store/reducers/menuStat';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const dispatch = useDispatch();
    // const data = useSelector((state) => state.data.items);
    const status = useSelector((state) => state.menuStat.status);
    const error = useSelector((state) => state.menuStat.error);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchMenueStat());
        }
    }, [status, dispatch]);

    const [snackOptions, setSnackOptions] = useState({ isError: false, message: 'Super Admin Can Only Access Dashboard' });

    const [openSnack, setShowSnack] = useState(false);
    function handleSnackClose() {
        setShowSnack(false);
    }
    useEffect(() => {
        if (window.localStorage.getItem('authUser') && JSON.parse(window.localStorage.getItem('authUser'))?.role == 'super_admin') {
            setShowSnack(true);
            location.replace('/dashboard');
        }
    }, []);
    // console.log(menuItem);
    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    const links = [
        {
            name: 'Terms & Conditions',
            path: '/terms-and-conditions'
        },
        {
            name: 'Privacy Policy',
            path: '/privacy-policy'
        },
        {
            name: 'Refund Policy',
            path: '/refund-policy'
        }
    ];

    return (
        <Box>
            {navGroups}
            <Snackbar
                open={openSnack}
                autoHideDuration={5000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ zIndex: '1000' }}
            >
                <Alert onClose={handleSnackClose} severity={'error'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
            {/* version */}
            {/* terms and condition */}
            <Box sx={{ mt: '2rem', pl: '28px', display: 'flex', gap: '0px 5px', flexWrap: 'wrap' }}>
                {links.map((link, index) => {
                    return (
                        <Box>
                            <Link key={index} to={link.path} style={{ color: '#1E7C88', textDecoration: 'none' }}>
                                <Typography sx={{ fontSize: '13px', opacity: '80%' }}>
                                    {link.name} {index != links.length - 1 ? '.' : ''}
                                </Typography>
                            </Link>
                        </Box>
                    );
                })}
            </Box>
            <Box sx={{ pl: '28px', pr: '16px', pb: '1.5rem' }}>
                <Typography sx={{ fontSize: '13px', fontWeight: '700', opacity: '70%', color: '#1E7C88' }}>©Omirror 2.0.0</Typography>
            </Box>
        </Box>
    );
};

export default Navigation;
