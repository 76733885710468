import React, { useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import axios from 'lib/axios';
import { toast } from 'sonner';

const AppStoreDeleteModal = ({ setOpenDeleteModal, setApplications, selectedId, isCategory = false }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            const res = await axios.delete(isCategory ? `/app-store/category/${selectedId}` : `/app-store/${selectedId}`);
            if (res.data.success) {
                toast.success(res.data.message);
                if (!isCategory) {
                    setApplications((prev) => prev.filter((app) => app.id !== selectedId));
                } else {
                    setApplications((prev) => prev.filter((app) => app.application_category_id !== selectedId));
                }
                setOpenDeleteModal(false);
                setIsLoading(false);
                console.log(isLoading);
            }
            console.log(res);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };
    console.log(isLoading);
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
            <Card sx={{ p: '1rem', maxWidth: { md: '50vw', sx: '100%' } }}>
                <Box>
                    <Typography sx={{ fontSize: '19px' }}>Are you sure delete this app?</Typography>

                    <Box display="flex" alignItems="center" gap={1} mt={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                background: '#ff4118',
                                '&:hover': {
                                    background: '#fe5e41'
                                }
                            }}
                            onClick={handleDelete}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Deleting...' : 'Yes'}
                        </Button>
                        <Button onClick={() => setOpenDeleteModal(false)} fullWidth variant="outlined">
                            No
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default AppStoreDeleteModal;
